import React from 'react';

const SVG = ({
  style = {},
  color = '#adb5b8',
  width = '25px',
  className = '',
  height = '25px',
  viewBox = '0 0 24 24'
}) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Pinterest</title>
    <path d="M6.788 11.003c-.714-1.34-.617-3.071.251-4.519C9.488 2.406 17.075 3.093 17.075 8.69c0 4.967-3.414 6.219-4.71 5.168-1.139-.92.868-3.608.868-5.931 0-3.961-5.828-3.51-5.828 1.397 0 .966.231 1.71.382 2.092-.27 1.105-1.365 5.603-1.591 6.538-.066.273-.613 5.394.629 5.98 1.645.777 3.607-5.2 4.023-6.925.776.527 1.81.856 2.843.856C23.801 17.864 25.445 0 12.301 0v.001C2.448.001-.093 10.093 4.239 14.103c1.185 1.09 3.173-1.931 2.549-3.1zM12.301 1.5c11.134 0 9.558 14.864 1.391 14.864-1.192 0-2.263-.609-2.571-1.173a.742.742 0 00-.701-.39c-1.296.074-.518 2.674-2.872 6.656-.216-3.944.501-3.831 1.758-9.932.087-.357-1.114-2.674.254-4.189.332-.369.767-.58 1.192-.58 2.015 0 .352 3.41-.165 5.539-.635 2.655 2.093 4.19 4.676 2.959.996-.476 3.313-2.067 3.313-6.564 0-7.127-9.589-8.362-12.821-2.978-1.122 1.868-1.246 4.126-.331 5.916-.031.232-.293.684-.531.984-2.798-3.42-.7-11.111 7.408-11.112z" />
  </svg>



export default SVG
