import ArrowIcon from './Arrows';
import BrandedMaterialsIcon from './BrandedMaterials';
import CancelIcon from './CancelIcon';
import ChevronIcon from './Chevron';
import CompleteControlIcon from './CompleteControlIcon';
import DedicatedDesignerIcon from './DedicatedDesigner';
import FastestDeliveryIcon from './FastestDeliveryIcon';
import FreeConsultationIcon from './FreeConsultation';
import HelpIcon from './Help';
import HomeIcon from './HomeIcon';
import LoginIcon from './LoginIcon';
import MagzineIcon from './MagzineIcon';
import MilestoneIcon from './MilestoneIcon';
import NoHiddenFeesIcon from './NoHiddenFeesIcon';
import OfferIcon from './OfferIcon';
import PaperLaunch from './PaperLaunch';
import PaymentsIcon from './PaymentsIcon';
import ProductIcon from './ProductIcon';
import RealTimeIcon from './RealTimeIcon';
import ReportsIcon from './ReportsIcon';
import TeamIcon from './TeamIcon';
import TeamFillIcon from './TeamIconFill';
import TrainedLabourIcon from './TrainedLabourIcon';
import TwitterIcon from './Twitter';
import WalletIcon from './WalletIcon';

/*** Social Icons ***/
import FacebookIcon from './FB';
import LinkedInIcon from './LinkedIn';
import InstagramIcon from './Instagram';
import PinterestIcon from './Pinterest';
import YoutubeIcon from './Youtube';

import EmailEnvelopeIcon from './EmailEnvelopeIcon';
import LocationIcon from './LocationIcon';
import PhoneIcon from './PhoneIcon';

import Burger from './Burger/Burger';

import FurnitureIcon from './Nav/Furniture';
import DesignIcon from './Nav/Design';
import MagzineNavIcon from './Nav/Magzine';
import TalkToUsIcon from './Nav/TalkToUs';

const Icons = {
  ArrowIcon: ArrowIcon,
  BrandedMaterialsIcon: BrandedMaterialsIcon,
  CancelIcon: CancelIcon,
  ChevronIcon: ChevronIcon,
  CompleteControlIcon: CompleteControlIcon,
  DedicatedDesignerIcon: DedicatedDesignerIcon,
  DesignIcon: DesignIcon,
  EmailEnvelopeIcon: EmailEnvelopeIcon,
  LocationIcon: LocationIcon,
  PhoneIcon: PhoneIcon,
  FastestDeliveryIcon: FastestDeliveryIcon,
  FacebookIcon: FacebookIcon,
  FurnitureIcon: FurnitureIcon,
  InstagramIcon: InstagramIcon,
  PinterestIcon: PinterestIcon,
  YoutubeIcon: YoutubeIcon,
  FreeConsultationIcon: FreeConsultationIcon,
  HelpIcon: HelpIcon,
  HomeIcon: HomeIcon,
  LinkedInIcon: LinkedInIcon,
  NoHiddenFeesIcon: NoHiddenFeesIcon,
  SupportIcon: TeamFillIcon,
  MilestoneIcon: MilestoneIcon,
  PaperLaunch: PaperLaunch,
  OfferIcon: OfferIcon,
  ProductIcon: ProductIcon,
  PaymentsIcon: PaymentsIcon,
  RealTimeIcon: RealTimeIcon,
  MagzineIcon: MagzineIcon,
  MagzineNavIcon: MagzineNavIcon,
  ReportsIcon: ReportsIcon,
  BurgerIcon: Burger,
  TeamIcon: TeamIcon,
  TwitterIcon: TwitterIcon,
  TalkToUsIcon: TalkToUsIcon,
  WalletIcon: WalletIcon,
  TrainedLabourIcon: TrainedLabourIcon,
  LoginIcon: LoginIcon,
  ProjectIcon: LoginIcon,
};

export default Icons;
