import React from 'react';
import { Link } from 'react-router-dom';
import LogoSvg from '../Icons/Logo/Logo';
import { PropTypes } from 'prop-types';
const LogoSVG = (props) => {
  return (
    <>
      {props.to !== '' ? (
        <Link to={props.to}>
          <Logo {...props} />
        </Link>
      ) : (
        <Logo {...props} />
      )}
    </>
  );
};

const Logo = (props) => {
  const { color, className, height, width, viewBox } = props;
  return (
    <LogoSvg
      color={color}
      className={className}
      height={height}
      width={width}
      viewBox={viewBox}
    />
  );
};

LogoSVG.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
};

export default LogoSVG;
