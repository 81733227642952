import createHmac from 'create-hmac';
const getImage = async (data, isMobile) => {
  let images = [];
  let x = await imxProxyAndFormat(data);
  images.push(x);
  return images;
};

const imxProxyAndFormat = async (image, isMobile) => {
  const KEY =
    process.env.IMXPROXY_KEY ||
    '37cb0bf46788f1037f27cb1706dc47595f3c5819183bb3d8f0daad49b244e148';
  const SALT =
    process.env.IMXPROXY_SALT ||
    'c622fbf16fd4d13d100e0cdfca872ec3144dff4306cf6bf9c681ef1c34f29020';
  const urlSafeBase64 = (string) => {
    return Buffer.from(string)
      .toString('base64')
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  };

  const hexDecode = (hex) => Buffer.from(hex, 'hex');

  const sign = (salt, target, secret) => {
    const hmac = createHmac('sha256', hexDecode(secret));
    hmac.update(hexDecode(salt));
    hmac.update(target);
    return urlSafeBase64(hmac.digest());
  };
  const attach_webp = image.format.attach_webp_replica;
  const name = image.id.relative_path;
  let ext = /[.]/.exec(name)[0] ? /[^.]+$/.exec(name)[0] : 'jpeg';
  const id = image.id.id;
  const img_title = null;
  const tag_type = image.tag_type;
  const alt = image.alt_tag;

  let image_data = [];

  const createUrl = (ext, width = null, e = null) => {
    const url = `https://imx-cdn.houzspace.com/${name}`;
    let path = '';
    const encoded_url = urlSafeBase64(url);
    if (width === null) {
      path = `/${encoded_url}.${ext}`;
    } else {
      path = `/w:${width}/${encoded_url}.${ext}`;
    }
    const signature = sign(SALT, path, KEY);
    let result;
    if (e === null) {
      result = `/${signature}${path}`;
    } else {
      result = `/${signature}${path} ${e}`;
    }
    return result;
  };

  let data_format = {
    alt_name: alt,
    id,
    proxy: true,
    allow_save: false,
    title: img_title,
    tag_type: tag_type,
    source_set: [],
    size: '',
    default_src: await createUrl(ext),
    attach_webp: attach_webp,
    webp_src: attach_webp ? await createUrl('webp') : null,
    default_size: '',
    default_format: ext,
    encoded: '',
  };

  if (!isMobile) {
    image.source_set.img.large.srcset.map(async (e) => {
      let width = e.replace('w', '');
      let x = await createUrl(ext, width, e);
      data_format.source_set.push(x);
    });
  } else {
    image.source_set.img.small.srcset.map(async (e) => {
      let width = e.replace('w', '');
      let x = await createUrl(ext, width, e);
      data_format.source_set.push(x);
    });
  }

  image_data.push(data_format);
  return image_data;
};

export default getImage;
