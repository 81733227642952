import React from "react";

const SVG = ({
  style = {},
  color = '#adb5b8',
  width = '18px',
  className = '',
  height = '18px',
  viewBox = '0 0 512.001 512.001'
}) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path d="M324.259 0c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533c94.106 0 170.667 76.561 170.667 170.667a8.536 8.536 0 008.533 8.533 8.53 8.53 0 008.533-8.533C511.992 84.216 427.777 0 324.259 0z" />
      <path d="M324.259 51.2c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533c65.877 0 119.467 53.589 119.467 119.467a8.536 8.536 0 008.533 8.533 8.53 8.53 0 008.533-8.533c0-75.28-61.244-136.533-136.533-136.533z" />
      <path d="M324.259 102.4c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533c37.641 0 68.267 30.626 68.267 68.267a8.536 8.536 0 008.533 8.533 8.53 8.53 0 008.533-8.533c0-47.052-38.281-85.333-85.333-85.333zM461.765 320.214a311.858 311.858 0 01-68.045-16.922c-21.239-7.962-45.372-2.825-61.534 13.124l-26.974 26.914c-56.559-33.348-103.808-80.503-137.233-136.951l26.991-26.931c15.957-16.102 21.111-40.209 13.133-61.397a310.574 310.574 0 01-16.964-67.994C187.051 21.231 162.091-.111 132.916 0H58.258c-1.741 0-3.482.085-5.205.239-15.506 1.4-29.543 8.747-39.519 20.677C3.559 32.854-1.169 47.958.248 63.582c8.269 77.722 35.149 153.472 77.688 219.008 38.622 60.655 91.162 113.092 151.876 151.595 65.417 42.342 140.962 69.163 218.641 77.585 1.758.154 3.524.23 5.299.23h.162c32.154-.137 58.197-26.351 58.069-58.411v-74.402c.675-29.499-20.864-54.843-50.218-58.973zm33.152 58.666v74.752c.085 22.682-18.338 41.216-41.062 41.31-1.331-.051-2.611-.051-3.738-.154-74.871-8.124-147.849-34.031-211.106-74.974C180.336 382.6 129.614 331.98 92.289 273.356c-41.123-63.35-67.081-136.515-75.06-211.446-.99-10.94 2.347-21.606 9.404-30.046s16.981-13.636 27.955-14.626a40.442 40.442 0 013.669-.171h75.145c20.446 0 37.965 15.147 40.823 35.302a327.613 327.613 0 0017.894 71.68c5.641 14.984 1.997 32.017-9.242 43.358l-31.607 31.539a8.528 8.528 0 00-1.391 10.266c36.164 63.488 88.909 116.122 152.508 152.209a8.524 8.524 0 0010.24-1.382l31.573-31.505c11.409-11.255 28.501-14.882 43.537-9.25 23.142 8.619 47.3 14.635 71.714 17.843 20.737 2.918 35.986 20.864 35.466 41.753z" />
      <path d="M128.001 34.134H58.258c-.717 0-1.425.034-2.133.102-8.695.785-14.012 5.717-16.401 8.576-1.92 2.295-6.306 8.559-5.538 17.161 3.226 30.268 9.062 59.716 17.365 87.535a8.547 8.547 0 008.175 6.093c.811 0 1.63-.111 2.449-.358 4.514-1.348 7.083-6.101 5.734-10.615C59.905 115.816 54.273 87.4 51.175 58.31c-.196-2.167 1.212-4.036 1.647-4.565.7-.836 2.261-2.278 5.436-2.543h69.735c4.71 0 8.533-3.814 8.533-8.525.008-4.712-3.815-8.543-8.525-8.543zM469.325 375.467a8.536 8.536 0 00-8.533 8.533l-.009 69.623a7.11 7.11 0 01-2.065 5.077c-1.34 1.348-3.115 2.091-4.804 2.099l-.785-.017c-29.158-3.166-57.335-8.781-83.746-16.691-4.497-1.357-9.267 1.212-10.624 5.726-1.357 4.514 1.212 9.267 5.726 10.624 27.401 8.209 56.61 14.029 86.955 17.323.7.068 2.125.102 2.33.102 6.46-.026 12.518-2.56 17.067-7.142 4.548-4.591 7.04-10.684 7.014-17.135l.008-69.589c0-4.71-3.823-8.533-8.534-8.533z" />
    </svg>
  );
}

export default SVG;
