import React from 'react';

const SVG = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 424" {...props}>
      <title>{'realtime'}</title>
      <circle cx={248} cy={148} r={35} fill="#00bcb7" />
      <path
        d="M0 336a40 40 0 0040 40h104v32h-32v16h272v-16h-32v-32h104a40 40 0 0040-40v-24H0zm336 72H160v-32h176zM16 328h464v8a24 24 0 01-24 24H40a24 24 0 01-24-24zM456 0h-32v16h32a24 24 0 0124 24v256h16V40a40 40 0 00-40-40zM16 40a24 24 0 0124-24h384V0H40A40 40 0 000 40v256h16z"
        fill="#3f3a34"
      />
      <path
        d="M248 263a115 115 0 10-115-115 115.13 115.13 0 00115 115zm-5-15.87a152.45 152.45 0 01-27.6-35 127 127 0 0127.6-3.93zm-9.58 4.73a104.51 104.51 0 01-52.94-23.58 96.09 96.09 0 0125.05-13.17 159.64 159.64 0 0027.89 36.75zM323 221.38a83.74 83.74 0 00-7.29-5.17l-5.36 8.44c2.11 1.34 3.84 2.57 5.22 3.62a104.55 104.55 0 01-53 23.6 162 162 0 0014.07-15.8l-7.88-6.15A150.6 150.6 0 01253 247.14v-39c1.56.06 3.13.06 4.65.16l.69-10c-1.76-.12-3.56-.13-5.35-.18V153h44.86a126.1 126.1 0 01-3.19 23.85l9.73 2.29a135.73 135.73 0 003.47-26.14h44.89A104.59 104.59 0 01323 221.38zM352.75 143h-44.89a135.73 135.73 0 00-3.46-26.15l-9.73 2.29a125.69 125.69 0 013.19 23.86H253V97.83c1.78-.06 3.6-.06 5.35-.18l-.69-10c-1.53.1-3.1.1-4.65.16v-39A150.35 150.35 0 01269 66.51l7.91-6.12a162.3 162.3 0 00-14.43-16.26 104.53 104.53 0 0153 23.6 77.612 77.612 0 01-5.22 3.62l5.36 8.44a81.73 81.73 0 007.29-5.17A104.61 104.61 0 01352.75 143zM243 48.89v39a127.22 127.22 0 01-27.59-3.93A153.44 153.44 0 01243 48.89zm-37.47 32a96.15 96.15 0 01-25.05-13.17 104.55 104.55 0 0152.94-23.58 159.87 159.87 0 00-27.89 36.73zM173 74.63A101 101 0 00200.92 90a141 141 0 00-9.32 26.9l9.74 2.29A129.09 129.09 0 01210.61 93 137.26 137.26 0 00243 97.85V143h-20v10h20v45.15a137.36 137.36 0 00-32.4 4.85 129.71 129.71 0 01-9.25-26.12l-9.74 2.29a141 141 0 009.32 26.91A101 101 0 00173 221.37 104.59 104.59 0 01143.26 153H163v-10h-19.74A104.59 104.59 0 01173 74.63z"
        fill="#3f3a34"
      />
      <path
        d="M288 228a20 20 0 1120-20 20 20 0 01-20 20zm0-30a10 10 0 1010 10 10 10 0 00-10-10zM193 168a20 20 0 1120-20 20 20 0 01-20 20zm0-30a10 10 0 1010 10 10 10 0 00-10-10zM288 108a20 20 0 1120-20 20 20 0 01-20 20zm0-30a10 10 0 1010 10 10 10 0 00-10-10z"
        fill="teal"
      />
    </svg>
  );
};

export default SVG;
