import React from 'react'
import {
  Route, Redirect, Switch
} from 'react-router-dom'

import Home from '../pages/Home'
import Login from '../pages/Login'
import HowItWorksPage from '../pages/HowItWorks'
import WithAuth from '../HComponents/WithAuth'
import WithoutAuth from '../HComponents/WithoutAuth'


const RoutesHandler = {
  'Home': Home,
  'HowItWorks': HowItWorksPage,
  'Login': Login
}

const RenderRoutes = (props) =>
  <Switch>
    {
      props.map((route, i) =>

        <Route
          key={route.key || i}
          path={route.desktop_path}
          exact={route.is_exact}

          render={(routeProps) => {
            if (route.is_auth_route) {
              return React.createElement(WithAuth(RoutesHandler[route.component]), { ...route, ...routeProps })

            }
            if (route.redirect.is_forceful_redirect) {
              return <Redirect to={{ pathname: route.redirect.not_loggedin_redirect_path, state: { from: routeProps.location } }} />
            }
            if (!route.is_auth_route) {
              return React.createElement(WithoutAuth(RoutesHandler[route.component]), { ...route, ...routeProps })
            }
            return <Redirect to={{ pathname: '/not-found', state: { from: routeProps.location } }} />
          }
          }
        />)

    }
  </Switch >

export default RenderRoutes