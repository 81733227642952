import React from 'react';

const SVG = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108" {...props}>
      <title>{'fastest_delivery'}</title>
      <path
        fill="#d6d6d6"
        d="M54 .004l54.002 54.001-54.001 54.002L-.001 54.005z"
      />
      <path
        fill="#e5e3e4"
        opacity={0.46}
        d="M58.246 4.241l49.752 49.752-49.752 49.752L8.494 53.993z"
      />
      <g fill="#3f3a34">
        <path d="M66.34 77.36a3.88 3.88 0 01.38-1.78H48.05a5.3 5.3 0 01.38 1.72 6 6 0 01-.38 1.84h18.67a4 4 0 01-.38-1.78zM31.44 77.51A2.62 2.62 0 0131.9 76h-9.6c-1.85 0-3.26.68-3.26 1.58s1.41 1.58 3.26 1.58h9.6a2.62 2.62 0 01-.44-1.53zM93.37 55.81L87.9 50l-8-15c-.44-.83-.64-1.43-1.69-1.43L64.9 34l1-3c0-1.41-.05-2.41-1.54-2.41H20.53c-1.49 0-3.63 0-3.63 1.41s2.51 2 4 2h41v17c0 1.41.51 2 2 2h19l7 7v14h-7a3.69 3.69 0 01.22 1.41v.17a10.67 10.67 0 01-.35 2.42h8.5a2.52 2.52 0 002.63-2.51V57.31a2.24 2.24 0 00-.53-1.5z" />
      </g>
      <path
        d="M30.78 62.55c0-1-1.08-1.85-2.49-1.85H16.84c-1.41 0-2.49.8-2.49 1.85s1.08 1.85 2.49 1.85h11.45c1.41 0 2.49-.8 2.49-1.85z"
        fill="teal"
      />
      <path
        d="M36.51 55.28c0-1-1.08-1.85-2.49-1.85H12.78c-1.41 0-2.49.8-2.49 1.85s1.08 1.85 2.49 1.85h21.24c1.41 0 2.49-.8 2.49-1.85z"
        fill="#38e8df"
      />
      <path
        d="M16.84 49.86h21.24c1.41 0 2.49-.8 2.49-1.85s-1.08-1.85-2.49-1.85H16.84c-1.41 0-2.49.8-2.49 1.85s1.08 1.85 2.49 1.85zM76.49 36.53H65.6v11.88h16.83l-5.94-11.88z"
        fill="teal"
      />
      <g fill="#3f3a34">
        <path d="M83.71 74.45a7.67 7.67 0 00-.33-2.45 10 10 0 00-9.63-7.55A9.8 9.8 0 0064.13 72a7.67 7.67 0 00-.33 2.41 8.57 8.57 0 00.33 2.49 9.91 9.91 0 009.63 7.39 10.06 10.06 0 009.63-7.39 7.86 7.86 0 00.33-2.49zM49.36 72a9.91 9.91 0 00-19.26 0 7.67 7.67 0 00-.33 2.41v.17A11.22 11.22 0 0030.1 77a10 10 0 0019.25 0 8.75 8.75 0 00.33-2.57 7.67 7.67 0 00-.32-2.43z" />
      </g>
      <g fill="#d6d6d6">
        <path d="M82.68 74.5a6.09 6.09 0 00-.26-1.91 7.91 7.91 0 00-7.64-6 7.78 7.78 0 00-7.64 6 6.09 6.09 0 00-.24 1.91 6.8 6.8 0 00.26 2 7.87 7.87 0 007.64 5.86 8 8 0 007.64-5.86 6.24 6.24 0 00.26-2zM48.53 72.58a7.91 7.91 0 00-15.36 0 6.12 6.12 0 00-.27 1.93v.13a9 9 0 00.26 1.92 8 8 0 0015.36 0 7 7 0 00.26-2.05 6.12 6.12 0 00-.25-1.93z" />
      </g>
      <path
        fill="#fff"
        opacity={0.1}
        d="M74.43 39.36h-6.22v6.23h9.61l-3.39-6.23z"
      />
      <path
        fill="#fff"
        opacity={0.1}
        d="M75.48 40.41h-6.22v6.23h9.61l-3.39-6.23z"
      />
    </svg>
  );
};

export default SVG;
