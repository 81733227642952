import React, { useState, useEffect } from 'react'
import ChevronIcon from '../Icons/Chevron'
import { LinkClick, CustomLink } from '../../components/Links/Links'
import Button from '../../components/Buttons/Buttons'
import { showDrawer, closeDrawer } from '../../store/utils/drawer'
import { useDispatch } from 'react-redux'

const FooterStrip = props => {
  const [showStrip, setShowStrip] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    return () =>
      dispatch(closeDrawer());
  });

  return (
    <div className={`footer_strip ${showStrip ? 'expand' : ''}`}>
      <div className="strip_content">
        <div className="heading">
          <h3 className={`${showStrip ? 'increase_font' : 'decrease_font'}`}>Ready to get started?</h3>
        </div>
        <div className="arrow_icon">
          <LinkClick icon={<ChevronIcon className={`${showStrip ? 'rotate' : ''}`} />} onClick={e => setShowStrip(v => !v)} />
        </div>
        <div className="flex have_questions">
          <h3>Have questions?&nbsp;&nbsp;&nbsp;</h3> <LinkClick text="Go to our FAQs" onClick={e => dispatch(showDrawer())} />
        </div>
        <div className="consult_btn">
          <Button text="Get Free Consultation Now" variant="outlined" color="white" />
        </div>
      </div>
    </div>
  )
}

export default FooterStrip