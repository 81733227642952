import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import MobileNavBar from './Mobile/MobileNavBar';
import HeaderLogoLeft from './Desktop/HeaderLogoLeft';

const NavBar = (props) => {
  const { isMobile } = useSelector((state) => state.utils.Info.device);
  return (
    <Fragment>{isMobile ? <MobileNavBar /> : <HeaderLogoLeft />}</Fragment>
  );
};

export default NavBar;
