import React, { useEffect } from 'react';
import Button from '../Buttons/Buttons';
import { useSelector, useDispatch } from 'react-redux';
import { alertClear } from '../../store/utils/alerts';
import { createPortal } from 'react-dom';
import CloseIcon from '../Icons/CancelIcon';

const SnackBar = () => {
  const alert = useSelector((state) => state.utils.Alerts);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(alertClear());
    }, alert.timeout);
    return () => clearTimeout(timer);
  });

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(alertClear());
  };

  return alert.showSnack
    ? createPortal(<Snack {...alert} onClick={handleClose} />, document.body)
    : null;
};

const Snack = (props) => {
  return (
    <div className="snackbar center_bottom">
      <div className="container">
        <Message message={props.message} />
        {props.showAction ? (
          <Action closeType={props.closeType} onClick={props.onClick} />
        ) : null}
      </div>
    </div>
  );
};

const Message = (props) => {
  return (
    <div className="message">
      <span className="">{props.message}</span>
    </div>
  );
};

const Action = (props) => {
  return (
    <div className="action">
      {props.closeType === 'button' ? (
        <CloseButtonAction onClick={props.onClick} />
      ) : (
        <CloseIconAction onClick={props.onClick} />
      )}
    </div>
  );
};

const CloseButtonAction = ({ onClick }) => {
  return (
    <Button
      variant="transparent"
      text="close"
      size="small"
      onClick={(e) => onClick(e)}
    ></Button>
  );
};
const CloseIconAction = ({ onClick }) => {
  return <CloseIcon aria-label="close" onClick={(e) => onClick(e)}></CloseIcon>;
};

export default SnackBar;
