import axios from 'axios';
import * as actions from '../actions/api';
import { alertShow } from '../utils/alerts';

const api = ({ dispatch, getState }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action);
  next(action);
  const {
    url,
    method,
    data,
    headers,
    withCredentials,
    onError,
    propType,
    onSuccess,
    onCompleted,
    onStart,
    extraPayload = '',
  } = action.payload;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
  if (onStart) dispatch({ type: onStart, payload: { type: propType } });
  axios.defaults.baseURL =
    process.env.REACT_APP_PROXY_BASE_URL ||
    'https://houzspace-ui-be-ug5fbktf7q-el.a.run.app/api/infinity/v1';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  try {
    const response = await axios.request({
      url,
      method,
      headers,
      [dataOrParams]: data,
      withCredentials,
    });
    //General

    dispatch({ type: actions.apiCallSuceess.type });
    //specific
    if (onSuccess)
      dispatch({ type: onSuccess, payload: response.data, extraPayload });
    console.log(response);
    dispatch({ type: onCompleted });
  } catch (error) {
    //General
    dispatch({ type: actions.apiCallFailed.type, payload: error.message });
    //alertShow
    dispatch({
      type: alertShow.type,
      payload: { message: error.response.data.message },
    });

    //Specific
    if (onError) dispatch({ type: onError, payload: error.message });
  }
};

export default api;
