import React from "react";

const SVG = ({
    style = {},
    color = '#adb5b8',
    width = '108px',
    className = '',
    height = '108px',
    viewBox = '0 0 108 108'
  }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox={viewBox}
    >
    <path fill="#d6d6d6" d="M0 54L54 0 108 54 54 108 0 54"></path>
      <path
        fill="#e5e3e4"
        d="M8.5 54L58.25 4.25 108 54 58.25 103.75 8.5 54"
        opacity="0.46"
      ></path>
      <path
        fill="#ca1f43"
        d="M29.29 54.42A2.64 2.64 0 0132 51.59L73 51a4.67 4.67 0 014.72 4.59l.07 4.65v1.86l-7.8.11a2.29 2.29 0 00-1.49.79l-3.41 4.2a1.17 1.17 0 000 1.43l3.52 4.1a2.29 2.29 0 001.54.69l7.8-.11v1.86l.08 5.59a4.67 4.67 0 01-4.59 4.72l-41 .58a2.79 2.79 0 01-2.83-2.75l-.41-28.86z"
      ></path>
      <path
        d="M73 51h-1.86a4.67 4.67 0 014.72 4.59l.07 4.65v1.86h1.86v-1.86l-.07-4.65A4.67 4.67 0 0073 51z"
        opacity="0.2"
      ></path>
      <path
        fill="#f6bb42"
        d="M72.37 72.43a.93.93 0 11-.92.94.93.93 0 01.92-.94z"
      ></path>
      <path
        fill="#ffce54"
        d="M69.59 73.4a2.79 2.79 0 112.83 2.75 2.79 2.79 0 01-2.83-2.75zm1.86 0a.93.93 0 10.92-.94.93.93 0 00-.91.92z"
      ></path>
      <path
        fill="#ee3944"
        d="M78.05 78.87l-7.8.11a2.29 2.29 0 01-1.54-.69l-3.52-4.1a1.17 1.17 0 010-1.43l3.41-4.2a2.29 2.29 0 011.52-.74l7.8-.11h1.86a.93.93 0 01.94.92l.13 9.31a.93.93 0 01-.92.94h-1.86zm-5.7-8.3a2.79 2.79 0 102.83 2.75 2.79 2.79 0 00-2.83-2.75z"
      ></path>
      <path
        fill="#fff"
        d="M67.05 74.16a1.17 1.17 0 010-1.43l3.4-4.2a2.29 2.29 0 011.55-.75h-1.86a2.29 2.29 0 00-1.52.74l-3.41 4.2a1.17 1.17 0 000 1.43l3.52 4.1a2.29 2.29 0 001.54.69h1.86a2.29 2.29 0 01-1.54-.69z"
        opacity="0.3"
      ></path>
      <path
        fill="#ee3944"
        d="M29.29 54.42a2.79 2.79 0 002.83 2.75l41-.58a4.67 4.67 0 014.72 4.59l.07 4.65v1.86l-7.8.11a2.29 2.29 0 00-1.52.74l-3.41 4.2a1.17 1.17 0 000 1.43l3.52 4.1a2.29 2.29 0 001.54.69l7.8-.11v1.86l.08 5.59a4.67 4.67 0 01-4.55 4.7l-41 .58a2.79 2.79 0 01-2.83-2.75l-.49-34.44z"
      ></path>
      <g opacity="0.2">
        <path d="M73.08 56.59h-1.86a4.67 4.67 0 014.72 4.59l.06 4.68v1.86h1.86v-1.86l-.07-4.65a4.67 4.67 0 00-4.71-4.62zM76.22 80.76l.08 5.59a4.67 4.67 0 01-4.59 4.72h1.86a4.67 4.67 0 004.59-4.72l-.08-5.59V78.9h-1.86z"></path>
      </g>
      <path
        fill="#fff"
        d="M31.64 88.84L31.19 57a2.78 2.78 0 01-1.9-2.6l.49 34.44a2.79 2.79 0 002.83 2.75h1.86a2.79 2.79 0 01-2.83-2.75z"
        opacity="0.3"
      ></path>
      <path
        fill="#82af48"
        d="M69.19 53.86L72.96 38.92 60.82 35.85 61.08 53.97 69.19 53.86z"
      ></path>
      <path
        fill="#a0d468"
        d="M66.9 53.89L69.77 42.59 68.54 39.82 60.22 37.71 60.45 53.98 66.9 53.89z"
      ></path>
      <path
        fill="#82af48"
        d="M35.63 29.45H60.77V54.15H35.63z"
        transform="rotate(-.81 48.512 42.078)"
      ></path>
      <path
        fill="#a0d468"
        d="M59.08 54L58.8 34.53 56.89 31.31 39.2 31.56 37.39 34.83 37.66 54.3 59.08 54z"
      ></path>
      <path
        d="M69.19 53.86L35.8 54.33 35.78 52.47 69.79 51.98 69.19 53.86z"
        opacity="0.2"
      ></path>
      <path
        d="M31.15 54.39A2.78 2.78 0 0133 51.75 2.79 2.79 0 1033 57a2.78 2.78 0 01-1.85-2.61z"
        opacity="0.2"
      ></path>
      <g fill="#3a3847">
        <path d="M79.92 79.31l-9.66.14a2.7 2.7 0 01-1.9-.86l-3.52-4.11a1.62 1.62 0 010-2l3.4-4.2a2.7 2.7 0 011.87-.91l9.66-.14a1.4 1.4 0 011.42 1.38l.13 9.31a1.4 1.4 0 01-1.4 1.39zm-9.82-11a1.82 1.82 0 00-1.16.56L65.54 73a.7.7 0 000 .83L69.07 78a1.82 1.82 0 001.18.53l9.66-.14a.47.47 0 00.46-.47l-.13-9.31a.47.47 0 00-.47-.46l-9.66.14z"></path>
        <path d="M72.43 76.62a3.26 3.26 0 113.21-3.3 3.26 3.26 0 01-3.21 3.3zM72.35 71a2.33 2.33 0 102.36 2.29A2.33 2.33 0 0072.35 71z"></path>
        <path d="M72.41 74.76a1.4 1.4 0 111.38-1.42 1.4 1.4 0 01-1.38 1.42zm0-1.86a.47.47 0 10.47.46.47.47 0 00-.5-.46zM43.22 50a.47.47 0 01-.48-.46.46.46 0 01.45-.47.47.47 0 010 .93zM43.19 48.17a.47.47 0 01-.48-.46.46.46 0 01.45-.47.47.47 0 010 .93zM43.17 46.31a.47.47 0 01-.48-.46.46.46 0 01.45-.47.47.47 0 010 .93zM43.14 44.45a.47.47 0 01-.48-.46.46.46 0 01.45-.47.47.47 0 010 .93zM43.11 42.59a.47.47 0 01-.48-.46.46.46 0 01.45-.47.47.47 0 010 .93zM43.09 40.72a.47.47 0 01-.48-.46.46.46 0 01.45-.47.47.47 0 010 .93z"></path>
        <path d="M59.06 52.6a.51.51 0 01-.47-.55l-.27-18.25a2.59 2.59 0 01-1.84-2.13l-16.85.24a2.59 2.59 0 01-1.78 2.18l.26 18.26a.47.47 0 11-.93 0l-.27-18.75a.51.51 0 01.46-.56 1.54 1.54 0 001.37-1.67.51.51 0 01.46-.56l17.69-.25a.51.51 0 01.47.55 1.54 1.54 0 001.42 1.64.51.51 0 01.47.54L59.51 52a.51.51 0 01-.45.6z"></path>
        <path d="M60.92 52.58a.51.51 0 01-.47-.55l-.32-22.62-24.2.34.32 22.62a.47.47 0 11-.93 0L35 29.22a.51.51 0 01.46-.56l25.14-.36a.51.51 0 01.47.55L61.38 52a.51.51 0 01-.46.58z"></path>
        <path d="M53.44 50.82c-.26 0-.47-.28-.47-.63L52.76 35c0-.35.2-.63.46-.64s.47.28.47.63l.21 15.17c.01.37-.2.66-.46.66zM50.64 49.93L46 50a.47.47 0 110-.93l4.63-.07a.47.47 0 110 .93zM49.2 47.16h-1.86a1.86 1.86 0 11-.05-3.72h1.86a1.86 1.86 0 01.05 3.72zm-1.9-2.77a.93.93 0 100 1.86h1.86a.93.93 0 000-1.86zM49.14 42.5h-1.86a1.86 1.86 0 11-.05-3.72h1.86a1.86 1.86 0 11.05 3.72zm-1.9-2.77a.93.93 0 100 1.86h1.86a.93.93 0 000-1.86zM49.14 37.58h-1.86a1.86 1.86 0 01-.05-3.72h1.86a1.86 1.86 0 11.05 3.72zm-1.9-2.77a.93.93 0 100 1.86h1.86a.93.93 0 000-1.86z"></path>
        <path d="M46.93 50.91a.46.46 0 01-.33-.13l-.94-.92a.47.47 0 11.65-.67l.94.92a.47.47 0 01-.32.8zM50.65 50.86a.47.47 0 01-.47-.46v-1.86a.47.47 0 11.93 0v1.86a.47.47 0 01-.46.46zM67.87 52.48h-.12a.47.47 0 01-.34-.57l2.41-9.53a2.33 2.33 0 01-1.32-2.21l-5.76-1.46a.47.47 0 01-.34-.57.47.47 0 01.6-.34l6.17 1.56a.47.47 0 01.29.21.46.46 0 01.05.35 1.39 1.39 0 001 1.7.46.46 0 01.34.57l-2.51 9.94a.47.47 0 01-.47.35z"></path>
        <path d="M69.8 52.45h-.12a.46.46 0 01-.34-.57l3.19-12.61-9.81-2.48a.47.47 0 01-.34-.57.47.47 0 01.56-.34l10.26 2.6a.46.46 0 01.34.57L70.25 52.1a.46.46 0 01-.45.35zM77.81 61.65a.47.47 0 01-.47-.46 4.19 4.19 0 00-4.25-4.13.47.47 0 010-.93 5.13 5.13 0 015.19 5 .47.47 0 01-.47.52zM73.57 91.5l-41 .58a.47.47 0 110-.93l41-.58a4.19 4.19 0 004.13-4.25.47.47 0 11.93 0 5.13 5.13 0 01-5.06 5.18zM77.75 57.54a.47.47 0 01-.47-.46v-1.47A4.19 4.19 0 0073 51.47a.47.47 0 010-.93 5.13 5.13 0 015.19 5v1.47a.47.47 0 01-.44.53zM32.13 57.64a3.26 3.26 0 01-3.3-3.21.47.47 0 01.93 0 2.33 2.33 0 002.36 2.29.47.47 0 010 .93z"></path>
        <path d="M32.61 92.08a3.26 3.26 0 01-3.3-3.21.47.47 0 11.93 0 2.33 2.33 0 002.36 2.29.47.47 0 010 .93zM29.29 54.89a.47.47 0 01-.47-.46A3.26 3.26 0 0132 51.12a.47.47 0 010 .93 2.33 2.33 0 00-2.29 2.36.47.47 0 01-.42.48zM73.09 57.06l-41 .58a.47.47 0 010-.93l41-.58a.47.47 0 010 .93zM72.12 54.28L33 54.83a.47.47 0 110-.93l39.1-.55a.47.47 0 010 .93z"></path>
        <path d="M29.78 89.33a.47.47 0 01-.47-.46l-.49-34.45a.47.47 0 01.93 0l.49 34.44a.47.47 0 01-.46.47zM73 51.47h-.93a.47.47 0 110-.93H73a.47.47 0 010 .93zM33.91 52h-1.86a.47.47 0 010-.93h1.86a.47.47 0 010 .93zM34 60.41h-.9a.47.47 0 110-.93h.9a.47.47 0 010 .93zM70.38 59.89h-1.82a.47.47 0 010-.93h1.82a.47.47 0 110 .93zm-3.63.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.64.06h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.64 0h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 010 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 010 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 010 .93zM73.13 59.85h-.93a.47.47 0 110-.93h.93a.47.47 0 110 .93zM34.44 89.27h-.94a.47.47 0 110-.93h.93a.47.47 0 010 .93zM70.79 88.75h-1.82a.47.47 0 010-.93h1.82a.47.47 0 110 .93zm-3.63.05h-1.82a.47.47 0 010-.93h1.82a.47.47 0 110 .93zm-3.63.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 010 .93zm-3.64.1h-1.82a.47.47 0 110-.93h1.82a.47.47 0 010 .93zm-3.64 0h-1.82a.47.47 0 110-.93h1.82a.47.47 0 110 .93zm-3.61.06h-1.82a.47.47 0 110-.93H49a.47.47 0 010 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 010 .93zm-3.64.05H39.9a.47.47 0 110-.93h1.82a.47.47 0 010 .93zm-3.64.05h-1.82a.47.47 0 110-.93h1.82a.47.47 0 010 .93zM73.53 88.71h-.93a.47.47 0 010-.93h.93a.47.47 0 110 .93zM77.87 66.3a.47.47 0 01-.47-.46l-.07-4.65a.47.47 0 11.93 0l.07 4.65a.47.47 0 01-.46.46zM78.16 86.78a.47.47 0 01-.47-.46l-.08-5.59a.47.47 0 11.93 0l.08 5.59a.47.47 0 01-.46.46z"></path>
      </g>
    </svg>
  );
}

export default SVG;
