import { combineReducers } from 'redux';
import routesReducer from '../routes';
import alertReducer from '../utils/alerts';
import drawerReducer from '../utils/drawer';
import infoReducer from '../utils/info';
import modalReducer from '../utils/modals';

export default combineReducers({
  Routes: routesReducer,
  Alerts: alertReducer,
  Info: infoReducer,
  Drawer: drawerReducer,
  Modal: modalReducer,
});
