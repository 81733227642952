import React from 'react';

const Login = (props) => {


  return (
    <div>
      'Login'
    </div>
  );
}


export default Login
