import React from 'react';
import { connect } from 'react-redux';
import { history } from '../helpers/history'


export default function (ComposedComponent) {
  class PublicRoutes extends React.Component {

    componentDidMount() {
      this._checkAndRedirect();
    }

    componentDidUpdate() {
      this._checkAndRedirect();
    }

    _checkAndRedirect() {

      const { is_auth_route, restricted } = this.props;
      if (is_auth_route) history.push('/auth')
      if (restricted) history.push('/access-denied')
    }

    render() {
      return (
        <div>
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      isLoggedIn: true
    };
  };


  return connect(
    mapStateToProps,
    null
  )(PublicRoutes);
}
