import React from 'react';

const SVG = (props) => {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.63 99.88"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path" transform="translate(-13.18 -4.06)">
          <path fill="none" d="M13 4.06h90v99.88H13z" />
        </clipPath>
        <style>
          {
            '.prefix__cls-3{fill:#00bcb7}.prefix__cls-4{fill:teal}.prefix__cls-6{fill:#3f3a34}.prefix__cls-7{fill:#fff}'
          }
        </style>
      </defs>
      <title>{'reports_icon'}</title>
      <path
        d="M84.53 9.76v88.48a4.24 4.24 0 01-4.24 4.24h-4a4.24 4.24 0 004.24-4.24V9.76a4.24 4.24 0 00-4.23-4.24h4a4.24 4.24 0 014.24 4.24zm0 0"
        transform="translate(-13.18 -4.06)"
        fill="#efedef"
      />
      <path
        className="prefix__cls-3"
        d="M29 5.52V16a3.31 3.31 0 01-3.3 3.31h-11zm0 0"
        transform="translate(-13.18 -4.06)"
      />
      <path
        className="prefix__cls-4"
        d="M101.35 28.45a16.82 16.82 0 11-16.82-16.82 16.82 16.82 0 0116.82 16.82zm0 0"
        transform="translate(-13.18 -4.06)"
      />
      <path
        className="prefix__cls-3"
        d="M101.35 28.45A16.82 16.82 0 0175.1 42.38a16.82 16.82 0 0015.53-29.61 16.83 16.83 0 0110.72 15.68zm0 0M24.19 29.92h19.35a1.46 1.46 0 100-2.93H24.19a1.46 1.46 0 100 2.93zm0 0"
        transform="translate(-13.18 -4.06)"
      />
      <g clipPath="url(#prefix__clip-path)">
        <path
          className="prefix__cls-4"
          d="M24.19 37.31h34.47a1.46 1.46 0 100-2.93H24.19a1.46 1.46 0 100 2.93zm0 0"
          transform="translate(-13.18 -4.06)"
        />
        <path
          className="prefix__cls-6"
          d="M86 10.23v-.47a5.71 5.71 0 00-5.7-5.7H29a2.73 2.73 0 00-1.7.94L13.68 17.14a1.46 1.46 0 00-.49 1.09V65a1.46 1.46 0 102.93 0V20.75h9.55A4.77 4.77 0 0030.43 16V7h49.86a2.78 2.78 0 012.78 2.78v.47a18.18 18.18 0 00-8.61 3 1.46 1.46 0 101.61 2.44 15.35 15.35 0 11-4.43 4.46 1.46 1.46 0 10-2.45-1.6 18.29 18.29 0 0013.88 28.13v51.56a2.78 2.78 0 01-2.78 2.76h-61.4a2.78 2.78 0 01-2.78-2.78V71.84a1.46 1.46 0 00-2.93 0v26.4a5.71 5.71 0 005.7 5.7h61.41a5.71 5.71 0 005.7-5.7V46.68a18.28 18.28 0 000-36.45zM27.5 16a1.84 1.84 0 01-1.84 1.84h-7.38L27.5 9zm0 0"
          transform="translate(-13.18 -4.06)"
        />
      </g>
      <path
        className="prefix__cls-7"
        d="M81.79 24.63a1.22 1.22 0 00-1.13-.76 1.22 1.22 0 00-1.13.76L76.36 33a1 1 0 101.93.73l.53-1.4h3.65l.53 1.4a1 1 0 001 .67 1 1 0 00.36-.07 1 1 0 00.6-1.33l-3.16-8.34zm-2.18 5.59l1-2.75 1 2.75zm0 0M90.2 20.37a1.46 1.46 0 00-1.46 1.46v1.46h-1.53a1.46 1.46 0 000 2.93h1.53v1.53a1.46 1.46 0 002.93 0v-1.53h1.46a1.46 1.46 0 000-2.93h-1.46v-1.46a1.46 1.46 0 00-1.46-1.46zm0 0"
        transform="translate(-13.18 -4.06)"
      />
      <path
        className="prefix__cls-6"
        d="M24.19 55.46H75a1.46 1.46 0 100-2.93H24.19a1.46 1.46 0 100 2.93zm0 0M24.19 62.73H75a1.46 1.46 0 100-2.93H24.19a1.46 1.46 0 100 2.93zm0 0M24.19 70H75a1.46 1.46 0 100-2.93H24.19a1.46 1.46 0 100 2.93zm0 0M24.19 77.27H48a1.46 1.46 0 100-2.93H24.19a1.46 1.46 0 100 2.93zm0 0"
        transform="translate(-13.18 -4.06)"
      />
    </svg>
  );
};

export default SVG;
