/* eslint-disable import/no-anonymous-default-export */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducers from './reducers';
//import logger from './middleware/logger'
import api from './middleware/api';
import { loadState } from './localstore';
const persistedState = loadState();

export default () => {
  return configureStore({
    reducer: reducers,
    persistedState,
    middleware: [...getDefaultMiddleware(), api],
  });
};
