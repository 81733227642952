import React, { Fragment } from 'react';
import RenderRoutes from './renderRoutes';
import { useSelector } from 'react-redux';

const RoutesHelper = () => {
  const { routes } = useSelector((state) => state.utils.Routes);
  return <Fragment>{RenderRoutes(routes)}</Fragment>;
};

export default RoutesHelper;
