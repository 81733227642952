import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LogoSVG from '../../Logo/index';
// import ChevronIcon from '../../Icons/Chevron';
import useSticky from '../../../hooks/useSticky';
import { MODAL_NAMES } from '../../Modals/ModalContainer';
import { modalShow } from '../../../store/utils/modals';
import { CustomLink, LinkClick } from './../../Links/Link';
import { SubMenu } from './SubMenu';

const HeaderLogoLeft = (props) => {
  const dispatch = useDispatch();
  const { isSticky, element } = useSticky();
  const data = useSelector((state) => state.ui.Navbar.data);
  const [status, setStatus] = useState(data.map((x) => false));
  const updateStatus = (value, index) => {
    const clone = [...status];
    clone[index] = value;
    setStatus(clone);
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log('clicked');
    dispatch(modalShow({ modalName: MODAL_NAMES['SignIn'] }));
  };
  return (
    <div className={`header ${isSticky ? 'sticky' : ''}`} ref={element}>
      <div className="header-logo-left">
        <div className="logo">
          <LogoSVG width="150" height="35" to="/" />
        </div>
        <div className="items">
          {data.length > 0
            ? data.map((e, index) =>
                e.link.type === 'LINK' ? (
                  <div
                    className="item"
                    key={e.id}
                    onMouseEnter={(e) => updateStatus(true, index)}
                    onMouseLeave={(e) => updateStatus(false, index)}
                    show={status[index]}
                  >
                    <CustomLink
                      text={e.nav_title}
                      icon=""
                      to={e.link.to}
                      activeOnlyWhenExact={e.link.active_only_when_exact}
                    />
                    {e.dropdown !== null && e.dropdown.sub_menu.length > 0 ? (
                      status[index] ? (
                        <SubMenu
                          {...e.dropdown}
                          onClick={(e) => updateStatus(false, index)}
                        />
                      ) : null
                    ) : null}
                  </div>
                ) : (
                  <div className="item" key={e.id}>
                    <LinkClick
                      text={e.nav_title}
                      icon=""
                      onClick={handleClick}
                      activeOnlyWhenExact={e.link.active_only_when_exact}
                    />
                  </div>
                )
              )
            : null}
        </div>
      </div>
    </div>
  );
};

export default HeaderLogoLeft;
