import React from 'react';

const SVG = ({
  style = {},
  color = '#adb5b8',
  width = '25px',
  className = '',
  height = '25px',
  viewBox = '0 0 24 24'
}) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path d="M4.75 24h14.5A4.756 4.756 0 0024 19.25V4.75A4.756 4.756 0 0019.25 0H4.75A4.756 4.756 0 000 4.75v14.5A4.756 4.756 0 004.75 24zM1.5 4.75A3.254 3.254 0 014.75 1.5h14.5a3.254 3.254 0 013.25 3.25v14.5a3.254 3.254 0 01-3.25 3.25H4.75a3.254 3.254 0 01-3.25-3.25z" />
    <path d="M12 18.13c3.38 0 6.13-2.75 6.13-6.13S15.38 5.87 12 5.87 5.87 8.62 5.87 12s2.75 6.13 6.13 6.13zm0-10.76c2.553 0 4.63 2.077 4.63 4.63s-2.077 4.63-4.63 4.63S7.37 14.553 7.37 12 9.447 7.37 12 7.37zM18.358 7.362c.986 0 1.729-.74 1.729-1.721 0-1.023-.782-1.721-1.728-1.721-.986 0-1.729.74-1.729 1.721 0 1.021.778 1.721 1.728 1.721zm.177-1.886c.316.279-.405.618-.405.166 0-.27.367-.2.405-.166z" />
  </svg>

export default SVG


