import React, { Fragment } from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    padding: '0px',
    inset: 0,
  },
};

ReactModal.setAppElement('#houzspace');
const Modal = ({
  children,
  className,
  onClose,
  showModal,
  hasCloseButton = true,
}) => {
  return (
    <Fragment>
      <ReactModal
        isOpen={showModal}
        contentLabel="Houzspace"
        onRequestClose={onClose}
        className={className}
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        {children}
      </ReactModal>
    </Fragment>
  );
};

export default Modal;
