import React from "react";

const SVG = ({
  style = {},
  color = '#adb5b8',
  width = '18px',
  className = '',
  height = '18px',
  viewBox = '0 0 512 512'
}) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path d="M405.333 213.874V106.667c0-23.531-19.135-42.667-42.667-42.667h-320C19.135 64 0 83.135 0 106.667V320c0 23.531 19.135 42.667 42.667 42.667h239.215C295.858 411.84 341.073 448 394.667 448c20.625 0 40.906-5.427 58.677-15.708 5.094-2.948 6.844-9.469 3.885-14.573-2.948-5.104-9.479-6.865-14.573-3.885-14.521 8.396-31.115 12.833-47.99 12.833-52.938 0-96-43.063-96-96s43.063-96 96-96 96 43.063 96 96v10.667c0 11.76-9.573 21.333-21.333 21.333-11.76 0-21.333-9.573-21.333-21.333v-42.667A10.66 10.66 0 00437.333 288c-2.869 0-5.447 1.161-7.362 3-9.428-8.401-21.714-13.667-35.305-13.667-29.406 0-53.333 23.927-53.333 53.333S365.26 384 394.667 384c15.896 0 30.03-7.131 39.81-18.202 7.727 10.977 20.44 18.202 34.857 18.202C492.865 384 512 364.865 512 341.333v-10.667c0-61.097-46.956-111.378-106.667-116.792zM42.667 85.333h320c.444 0 .816.227 1.254.254L211.438 210.75c-5.427 3.417-13.292 2.708-16.823.542L41.426 85.585c.433-.026.801-.252 1.241-.252zM384 213.874c-59.711 5.414-106.667 55.695-106.667 116.793 0 3.6.221 7.148.54 10.667H42.667c-11.76 0-21.333-9.573-21.333-21.333V106.667c0-3.021.667-5.874 1.805-8.48L182.022 228.48c6.208 4.052 13.344 6.188 20.646 6.188 7.021 0 13.885-1.979 19.927-5.729a10.367 10.367 0 001.719-1.156l157.88-129.598c1.139 2.608 1.807 5.461 1.807 8.483v107.206zm10.667 148.793c-17.646 0-32-14.354-32-32s14.354-32 32-32 32 14.354 32 32-14.354 32-32 32z" />
    </svg>
  );
}

export default SVG;
