import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile, deviceDetect } from 'react-device-detect';
//import ReactGA from 'react-ga';

//Actions
import { deviceInfo } from './store/utils/info';
import { loadNavs } from './store/ui/navbar';
import { loadRoutes } from './store/routes';
import { loadData, filterUiData } from './store/ui/fetchData';
import { useDispatch, useSelector } from 'react-redux';
import RoutesHelper from './routes/routerHelper';

/*** Custom Hooks */

//Components
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import ModalContainer from './components/Modals/ModalContainer';
import SnackBar from './components/Snackbar/SnackBar';

import SentryInit from './services/Sentry'; //Logger

const App = () => {
  //usePageViews();
  let location = useLocation();

  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_SENTRY_RELEASE
  ) {
    SentryInit();
  }
  const navFetchRequired = useSelector(
    (state) => state.ui.Navbar.navFetchRequired
  );

  const fetchedData = useSelector((s) => s.ui.FetchData.data);

  useEffect(() => {
    dispatch(filterUiData(fetchedData));
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadNavs());
    dispatch(loadRoutes());
    dispatch(deviceInfo({ isMobile, device: deviceDetect() }));
    dispatch(loadData(location));
  }, [location, navFetchRequired, dispatch]);

  return (
    <React.Fragment>
      {isMobile ? (
        <div className="turn">
          <h1>
            Please Rotate Your Device to access the app. We are sorry we don't
            support landscape mode yet
          </h1>
        </div>
      ) : (
        ''
      )}
      <div className="appView">
        <NavBar />
        <RoutesHelper />
        <Footer />
        <SnackBar />
        <ModalContainer />
      </div>
    </React.Fragment>
  );
};

export default App;
