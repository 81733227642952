import React from 'react';

const SVG = (props) => (
  <svg
    height="25pt"
    viewBox="-174 -23 745 745"
    width="25pt"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.363 696.855h-13.36V384.98H25.47zM48.645 409.93l1.843-24.95h203.649l7.39 24.95zM372.57 696.855l-82.332-277.94-10.066-33.935h35.672l69.304 311.875zm10.14-683.867l-67.17 347.04H-10.997v-12.473c0-13.778 11.168-24.95 24.953-24.95H275.93a12.458 12.458 0 0012.156-9.668L358.488 7.86c1.586-6.496 8.051-10.554 14.598-9.171 6.543 1.386 10.805 7.718 9.625 14.3zm0 0" />
  </svg>
);

export default SVG;
