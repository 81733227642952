import React from 'react';
import { closeModal } from '../../store/utils/modals';
import { useDispatch } from 'react-redux';
import CancelIcon from '../Icons/CancelIcon';
import LogoSVG from '../Logo';

const ModalSmall = ({ children, hasCloseButton = true }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };
  return (
    <div className="modal modal-mobile">
      <div className="modal-mobile-header">
        {hasCloseButton ? (
          <CancelIcon className="close" onClick={handleClose} />
        ) : (
          ''
        )}
        <LogoSVG
          color="#fff"
          to="/"
          height="75"
          width="150"
          viewBox="0 0 350 75"
        />
        <div className="modal-mobile-curve"></div>
      </div>
      <div className="modal-mobile-body">{children}</div>
    </div>
  );
};

export default ModalSmall;
