import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from './Modal';
import { closeModal } from '../../store/utils/modals';
//import MODAL_NAMES from './Modals';
import SignIn from './Designs/SignIn';
import FreeConsultation from './Designs/FreeConsulation';
//const SignIn = MODAL_NAMES['SignIn'];

export const MODAL_NAMES = {
  SignIn: 'SignIn',
  FreeConsultation: 'FreeConsulation',
};

const ModalContainer = (props) => {
  const dispatch = useDispatch();

  const ModalState = useSelector((state) => state.utils.Modal);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const onSubmitSignIn = () => {
    console.log('onSubmitButtonClicked');
  };

  const {
    className,
    hasCloseButton,
    modalName,
    params,
    showModal,
    size,
  } = ModalState;

  return showModal ? (
    <Modal
      className={className}
      showModal={showModal}
      hasCloseButton={hasCloseButton}
      onClose={(e) => handleCloseModal(e)}
    >
      {modalName === MODAL_NAMES.FreeConsultation && (
        <FreeConsultation onSubmit={(e) => onSubmitSignIn(e)} {...params} />
      )}
      {modalName === MODAL_NAMES.SignIn && (
        <SignIn onSubmit={(e) => onSubmitSignIn(e)} {...params} />
      )}
    </Modal>
  ) : null;
};
export default ModalContainer;
