import { combineReducers } from 'redux';
import routesReducer from '../routes';
import navbarReducer from '../ui/navbar';
import customHeadingBlockReducer from '../ui/customHeadingBlock';
import imageIconBlockReducer from '../ui/imageIconBlock';
import servicesGridReducer from '../ui/servicesGrid';
import designThemeReducer from '../ui/designTheme';
import tabsReducer from '../ui/tabs';
import customerFeedbackReducer from '../ui/customerFeedback';
import twoGridImageTextReducer from '../ui/twoGridImageText';
import BannerReducer from '../ui/banner';

import fetchDataReducer from '../ui/fetchData';
// import onlineExp from '../ui/onlineExp';

export default combineReducers({
  FetchData: fetchDataReducer,
  Routes: routesReducer,
  Banner: BannerReducer,
  Navbar: navbarReducer,
  CustomHeading: customHeadingBlockReducer,
  ImageIcon: imageIconBlockReducer,
  ServicesGrid: servicesGridReducer,
  DesignTheme: designThemeReducer,
  Tabs: tabsReducer,
  CustomerFeedback: customerFeedbackReducer,
  TwoGrid: twoGridImageTextReducer,
});
