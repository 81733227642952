import React, { Fragment } from 'react';
import { CustomLink, LinkClick } from '../../Links/Link';
import LazyImage from './../../Picture/LazyImage';

const SubMenu = ({ sub_menu, image, column, onClick }) => {
  return (
    <Fragment>
      <div className="border"></div>
      <div
        className={`${column === 1 ? 'nav_submenu_single' : ''} nav_submenu`}
        onClick={(e) => onClick(e)}
      >
        <ul>
          {sub_menu.map((e) => (
            <li className="nav__submenu-item " key={e.id}>
              <CustomLink
                text={e.nav_title}
                key={e.id}
                icon=""
                to={e.link.to}
                activeOnlyWhenExact={e.link.active_only_when_exact}
              />
            </li>
          ))}
        </ul>
        {column !== 1 ? <LazyImage {...image} /> : null}
      </div>
    </Fragment>
  );
};

export { SubMenu };
