import React from 'react';

const SVG = ({
    style = {},
    color = '#adb5b8',
    width = '18px',
    className = '',
    height = '18px',
    viewBox = '0 0 18 18'
  }) =>
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Arrows</title>
      <symbol id="arrows" viewBox="0 0 17 13.029">
            <path d="M11.447,0.196c-0.231-0.262-0.617-0.262-0.856,0c-0.231,0.252-0.231,0.672,0,0.924l4.344,4.735H0.6 	C0.266,5.856,0,6.146,0,6.51c0,0.364,0.266,0.663,0.6,0.663h14.335l-4.344,4.726c-0.231,0.261-0.231,0.682,0,0.934 	c0.239,0.261,0.626,0.261,0.856,0l5.373-5.856c0.239-0.252,0.239-0.673,0-0.924L11.447,0.196z" /> </symbol>
    </svg>
  
  export default SVG
  