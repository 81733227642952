import React from 'react';

const SVG = ({
  style = {},
  color = '#adb5b8',
  width = '25px',
  className = '',
  height = '25px',
  viewBox = '0 0 24 24'
}) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Facebook</title>
    <path d="M6.812 13.937H9.33v9.312c0 .414.335.75.75.75l4.007.001a.75.75 0 00.75-.75v-9.312h2.387a.75.75 0 00.744-.657l.498-4a.75.75 0 00-.744-.843h-2.885c.113-2.471-.435-3.202 1.172-3.202 1.088-.13 2.804.421 2.804-.75V.909a.75.75 0 00-.648-.743A26.926 26.926 0 0015.071 0c-7.01 0-5.567 7.772-5.74 8.437H6.812a.75.75 0 00-.75.75v4c0 .414.336.75.75.75zm.75-3.999h2.518a.75.75 0 00.75-.75V6.037c0-2.883 1.545-4.536 4.24-4.536.878 0 1.686.043 2.242.087v2.149c-.402.205-3.976-.884-3.976 2.697v2.755c0 .414.336.75.75.75h2.786l-.312 2.5h-2.474a.75.75 0 00-.75.75V22.5h-2.505v-9.312a.75.75 0 00-.75-.75H7.562z" />
  </svg>

export default SVG
