import { apiCallBegan } from '../actions/api';
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { setImageIconBlock } from './imageIconBlock';
import { setCustomHeadingBlock } from './customHeadingBlock';
import { setDesignTheme } from './designTheme';
import { setServicesGrid } from './servicesGrid';
import { setTabs } from './tabs';
import { setCustomerFeedback } from './customerFeedback';
import { setTwoGridImageText } from './twoGridImageText';
import { setBanner } from './banner';

const slice = createSlice({
  name: 'uiData',
  initialState: {
    data: [],
    isFetching: false,
    lastFetchTimeStamp: null,
    route: '',
    recent_routes: [],
    isFetchedSuccess: false,
  },
  reducers: {
    uiDataFetchBegan: (uiData, action) => {
      uiData.isFetching = true;
      var d = new Date();
      var n = d.getTime();
      uiData.lastFetchTimeStamp = n;
    },
    uiDataFetchSuccess: (uiData, action) => {
      uiData.data = action.payload.data;
      uiData.isFetchedSuccess = true;
    },
    uiDataFetchCompleted: (uiData, action) => {
      uiData.isFetching = false;
    },
    uiDataFetchFailed: (uiData, action) => {
      uiData.isFetching = false;
    },
    uiDataStoreRoute: (uiData, action) => {
      uiData.route = action.payload;
      uiData.recent_routes.push(action.payload.pathname);
    },
  },
});

export const {
  uiDataFetchBegan,
  uiDataFetchSuccess,
  uiDataFetchCompleted,
  uiDataFetchFailed,
  uiDataStoreRoute,
} = slice.actions;
export default slice.reducer;

export const filterUiData = (data) => async (dispatch, getState) => {
  dispatch(setCustomHeadingBlock(data.custom_heading_block));
  dispatch(setImageIconBlock(data.icon_image_block));
  dispatch(setDesignTheme(data.design_themes));
  dispatch(setServicesGrid(data.services_grid));
  dispatch(setTabs(data.tabs));
  dispatch(setBanner(data.banner));
  dispatch(setCustomerFeedback(data.customer_feedback));
  dispatch(setTwoGridImageText(data.two_grid_image_text));
};

const objToString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const loadData = (route) => async (dispatch, getState) => {
  const { isMobile } = getState().utils.Info.device;
  let display = isMobile ? 'small' : 'large';

  const { lastFetchTimeStamp } = getState().ui.FetchData;
  if (lastFetchTimeStamp != null) {
    const diffInMinutes = moment().diff(moment(lastFetchTimeStamp), 'minutes');
    if (diffInMinutes < 10) return;
  }

  dispatch(uiDataStoreRoute(route));
  dispatch(
    apiCallBegan({
      url: `web/${display}?${objToString(route)}`,
      onStart: uiDataFetchBegan.type,
      onSuccess: uiDataFetchSuccess.type,
      onError: uiDataFetchFailed.type,
      onCompleted: uiDataFetchCompleted.type,
    })
  );
};
