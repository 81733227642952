import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'drawer',
  initialState: {
    showDrawer: false,
    component: null,
    showCloseButton: true,
  },
  reducers: {
    showDrawer: (drawer, { payload = {} }) => {
      const { component = null, showCloseButton = true } = payload;

      drawer.showDrawer = true;
      drawer.component = component;
      drawer.showCloseButton = showCloseButton;
    },
    closeDrawer: (drawer, action) => {
      drawer.showDrawer = false;
      drawer.component = null;
    },
  },
});
export const { showDrawer, closeDrawer } = slice.actions;

export default slice.reducer;
