import React from 'react'

const Burger = ({
  isOpen,
  direction = null,
}) => {
    return(
        <div className={`burger burger-slide ${isOpen ? 'open' : ''}`}>
            <div className="burger-lines" />
        </div>
    )
}
    
  


export default Burger