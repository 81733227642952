import React from 'react';

const SVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 91.61 104.94"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a" transform="translate(-8.19 -1.53)">
          <path fill="none" d="M8 1.53h92v104.94H8z" />
        </clipPath>
      </defs>
      <title>{'product'}</title>
      <g clipPath="url(#prefix__a)">
        <path
          d="M91.17 50.88a3.07 3.07 0 00-2.63-1.48 26 26 0 00-22.12 12.3H29.96a15.27 15.27 0 00-10.87 4.5L.9 84.38a3.08 3.08 0 000 4.35c7.47 7.46 15.3 15.24 15.38 15.32a3.07 3.07 0 004.33 0l11.67-11.58h25.76A21.45 21.45 0 0077.1 80.91c.09-.18 9.41-17.95 14.17-27a3.07 3.07 0 00-.09-3zm-16 27.09c-2.66 5.08-10.64 10.75-16.37 10.75l-25.69.06c-.81 0-2.84.28-3.42.85l-11.63 11.09c-2.45-2.43-9.8-9.49-14.16-13.84L22.65 68.8a11.89 11.89 0 017.5-3l36.66-.83-4.67 8c-.53 1-3.83 2.53-6.7 2.53l-18.3.3c-1.7 0-4.75.64-4.75 2.33s3.14 1.34 4.84 1.34l21.32-.57a8 8 0 007.65-4.43l7.27-14.33c2.58-4.87 9.21-6.2 14.34-7.67-4.9 9.34-10.75 21.68-10.83 21.83zm-3.48.08"
          fill="#403b34"
        />
      </g>
      <path
        d="M30.12 55.54h36.89a3.08 3.08 0 003.08-3.07v-37.1a3.08 3.08 0 00-3.07-3.07h-9.21V9.22a9.225 9.225 0 10-18.45 0v3.07h-9.24a3.07 3.07 0 00-3.07 3.07v37.11a3.07 3.07 0 003.07 3.07zm11.94-48.4c0-2.78 2.91-5 6.5-5s6.5 2.26 6.5 5v5h-13zm-12.25 8.95l9.55-.12v5.55c0 1.7.58 2.82 2.28 2.82s2.56-1.13 2.56-2.82l-.06-5.61 9 3-.12 3.56a2.3 2.3 0 002.63 2.54c1.7 0 2.15-1.79 2.15-3.49v-5.55h8.46l.11 36.5H30zm3.39 2.38"
        fill="#403b34"
      />
      <path fill="#15bbb7" d="M51.32 41.25h-5.51l2.75-6.17 2.76 6.17z" />
      <path
        fill="teal"
        d="M40.49 41.25h-5.52l2.76-6.17 2.76 6.17zM62.28 41.25h-5.52l2.76-6.17 2.76 6.17z"
      />
      <circle cx={55.86} cy={14.78} r={0.8} fill="#15bbb7" />
    </svg>
  );
};

export default SVG;
