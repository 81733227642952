import React from 'react';
import { connect } from 'react-redux';
import { history } from '../helpers/history'

export default function (ComposedComponent) {
  class Authenticate extends React.Component {

    componentDidMount() {
      this._checkAndRedirect();
    }

    componentDidUpdate() {
      this._checkAndRedirect();
    }

    _checkAndRedirect() {
      const { isLoggedIn, redirect, is_auth_route, restricted } = this.props;
      if (restricted) {
        history.push('/access-denied')
      }

      if (redirect.is_forceful_redirect && isLoggedIn) {
        history.push(redirect.loggedin_redirect_path)
      }

      if (!isLoggedIn || !is_auth_route) {
        history.push(redirect.not_loggedin_redirect_path)
      }
    }

    render() {
      return (
        <div>
          { this.props.isLoggedIn ? <ComposedComponent {...this.props} /> : null}
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      isLoggedIn: false
    };
  };


  return connect(
    mapStateToProps,
    null
  )(Authenticate);
}
