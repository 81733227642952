import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'imageIconBlock',
  initialState: {
    data: [],
  },
  reducers: {
    setImageIconBlock: (uiData, action) => {
      uiData.data = action.payload;
    },
  },
});

export const { setImageIconBlock } = slice.actions;
export default slice.reducer;
