import React from 'react';

const SVG = (props) => (
  <svg
    height={25}
    width={25}
    viewBox="0 0 368 368"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M360 128H240V8c0-4.426-3.586-8-8-8H8C3.586 0 0 3.574 0 8v160c0 4.426 3.586 8 8 8h24v56c0 3.23 1.953 6.16 4.938 7.39.992.419 2.03.61 3.062.61a8.008 8.008 0 005.656-2.344L107.313 176H128v120c0 4.426 3.586 8 8 8h124.688l61.656 61.656a8.008 8.008 0 008.719 1.735A8.004 8.004 0 00336 360v-56h24c4.414 0 8-3.574 8-8V136c0-4.426-3.586-8-8-8zm-256 32c-2.121 0-4.16.84-5.656 2.344L48 212.688V168c0-4.426-3.586-8-8-8H16V16h208v144h-87.96c-.017 0-.024-.008-.04-.008s-.023.008-.04.008zm248 128h-24c-4.414 0-8 3.574-8 8v44.688l-50.344-50.344A7.978 7.978 0 00264 288H144V176h88c4.414 0 8-3.574 8-8v-24h112zm0 0" />
  </svg>
);

export default SVG;
