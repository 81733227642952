import React from 'react';

const ModalLarge = ({
  children,
  title,
  subtitle = '',
  icon = null,
  hasCloseButton = true,
}) => {
  return (
    <div className="modal">
      <div className="modal-left">
        <div className="modal-group">
          <h2>{title}</h2>
          <span className="modal-text">{subtitle}</span>
          <div className="modal-icon">{icon}</div>
        </div>
      </div>
      <div className="modal-right">{children}</div>
    </div>
  );
};

export default ModalLarge;
