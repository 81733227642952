import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'utils',
  initialState: {
    device: {},
  },
  reducers: {
    deviceInfo: (utils, action) => {
      utils.device = action.payload;
    },
  },
});

export const { deviceInfo } = slice.actions;
export default slice.reducer;
