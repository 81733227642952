import React from 'react';
import HeaderLink from '../Links/Links';
import Icons from '../Icons';
import Logo from '../Icons/Logo/Logo';
import { Link } from 'react-router-dom';

const FooterMain = (props) => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="wrapper">
      <div className="footer_primary">
        <RenderLogo />
        <div className="description">
          <h3>Happiness Gurantee</h3>
          <p>
            If you’re not happy, we’re not happy. We work tirelessly to make
            sure your houzspace experience is delightful. That’s why we offer
            the Houzspace Happiness Guarantee.
          </p>
          <p>&copy; {year} Houzspace, All rights reserved. </p>
          <PoliciesLinks />
        </div>
        <div className="link_group">
          <div className="link_group_items">
            <FooterBlockList title="Company" />
            <FooterBlockList title="Explore" />
          </div>
        </div>
        <div className="reach_us">
          <FooterReachUs title="Reach Us" />
        </div>
      </div>
    </div>
  );
};

const PoliciesLinks = (props) => {
  return (
    <div className="links">
      <Link to="/">Privacy Policy</Link> | <Link to="/"> Terms of use</Link> |
      <Link to="/"> Disclaimer</Link>
    </div>
  );
};

const RenderLogo = (props) => {
  return (
    <div className="logo">
      <Logo className="logo_footer" />
      <h3>Follow Us:</h3>
      <SocialIcons {...SocialIconsList} />
    </div>
  );
};

const SocialIcons = (props) => {
  return (
    <div className="social_icons">
      {props.social_icons.map((e) =>
        React.createElement(Icons[e.Icon], { key: e.title })
      )}
    </div>
  );
};

const FooterBlockList = (props) => {
  return (
    <div className="company">
      <h3>{props.title}</h3>
      <ul className="company_links_display">
        <FooterItem to="/" className="" title="Home2" />
        <FooterItem to="/" className="" title="Home2" />
        <FooterItem to="/" className="" title="Home2" />
      </ul>
    </div>
  );
};

const FooterReachUs = (props) => {
  return (
    <>
      <h3>{props.title}</h3>
      <ul>
        <li>
          {React.createElement(Icons['EmailEnvelopeIcon'])} Email:
          care@houzspace.com
        </li>
        <li>{React.createElement(Icons['PhoneIcon'])} Phone: +91-8595102608</li>
        <li>
          {React.createElement(Icons['LocationIcon'])} Address: Spaze Tower-B3,
          Sohna Road, Gurugram
        </li>
      </ul>
    </>
  );
};

const FooterItem = (props) => {
  return (
    <li className="">
      <HeaderLink
        to={props.to}
        className={props.className}
        activeClassName={'primary'}
        text={props.title}
      />
    </li>
  );
};

const SocialIconsList = {
  social_icons: [
    {
      Icon: 'InstagramIcon',
      URL: 'https://www.instagram.com/_houzspace/',
      title: 'instagram',
    },
    {
      Icon: 'FacebookIcon',
      URL: 'https://www.facebook.com/houzspace',
      title: 'facebook',
    },
    {
      Icon: 'PinterestIcon',
      URL: 'https://in.pinterest.com/houzspace/',
      title: 'pinterest',
    },
    {
      Icon: 'TwitterIcon',
      URL: 'https://twitter.com/_houzspace',
      title: 'twitter',
    },
  ],
};

export default FooterMain;
