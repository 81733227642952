import React from 'react';
import { useSelector } from 'react-redux';
import FooterMain from './Footer';
import FooterStrip from './FooterStrip';

const Footer = () => {
  const device = useSelector((state) => state.utils.Info.device);
  return (
    <div>
      <footer id="footer">
        <FooterMain />
        {!device.isMobile ? <FooterStrip /> : null}
      </footer>
    </div>
  );
};

export default Footer;
