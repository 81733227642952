import React from 'react';

const SVG = (props) => {
  return (
    <svg
      id="prefix__b6879283-12e3-4054-8d74-b386d4a06658"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 102 102"
      {...props}
    >
      <defs>
        <style />
      </defs>
      <title>{'magzine_icon'}</title>
      <path
        className="prefix__0523e706-b180-4de7-9548-035aadc59ef1"
        d="M22.52 103h-8.76A8.77 8.77 0 015 94.24V28.5h8.77V15.75h8.77zm0 0"
        transform="translate(-3 -3)"
        fill="#6bf2eb"
      />
      <path
        className="prefix__43ae54ef-285d-47fb-b54b-9f2dd8207506"
        fill="teal"
        d="M29.09 39.05h30.68V77.3H29.09z"
      />
      <path
        className="prefix__97410ace-c5b1-4584-9b6a-0c0e3a349159"
        fill="#00bcb7"
        d="M29.09 15.14h61.36v14.34H29.09z"
      />
      <path
        className="prefix__46fc51a4-b2b7-4798-9fc7-0b7af0cff7c2"
        d="M103 3H22.52a2 2 0 00-2 2v8.77h-6.76a2 2 0 00-2 2v10.74H5a2 2 0 00-2 2v65.73A10.77 10.77 0 0013.76 105h80.48A10.77 10.77 0 00105 94.24V5a2 2 0 00-2-2zm0 91c0 3.74-4.26 9-8 9H19c1.5-1.85 4-6.44 4-9V67c0-1.1.1-2-1-2s-1.47 1.25-1.47 2.35v26.89C20.53 98 16.74 102 13 102s-8-4.26-8-8V29h8v63c0 1.1-.1 2 1 2s1-.9 1-2V16h6l-.47 33.42c0 1.1.37 1.58 1.47 1.58s1-.9 1-2V5h80zm-2 .24"
        transform="translate(-3 -3)"
      />
      <path
        className="prefix__46fc51a4-b2b7-4798-9fc7-0b7af0cff7c2"
        d="M32.09 34.48h61.36a2 2 0 002-2V18.14a2 2 0 00-2-2H32.09a2 2 0 00-2 2v14.34a2 2 0 002 2zM32 18h62v14H32zm.08.13M32.09 82.29h30.68a2 2 0 002-2V42a2 2 0 00-2-2H32.09a2 2 0 00-2 2v38.3a2 2 0 002 2zM32 42h31v38H32zm2.08 2M93.45 40.05H72.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M93.45 53.91H72.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M93.45 61H72.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M93.45 68H72.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M93.45 75.06H72.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M32.09 90h61.36c1.1 0 2-.48 2-1.07s-.89-1.07-2-1.07H32.09c-1.1 0-2 .48-2 1.07S31 90 32.09 90zm0 0"
        transform="translate(-3 -3)"
      />
      <path
        d="M22.62 56.39A2 2 0 1024 57a2 2 0 00-1.41-.58zm0 0"
        transform="translate(-3 -3)"
        fill="#3b3b3b"
      />
    </svg>
  );
};

export default SVG;
