import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'banner',
  initialState: {
    data: [],
  },
  reducers: {
    setBanner: (uiData, action) => {
      uiData.data = action.payload;
    },
  },
});

export const { setBanner } = slice.actions;
export default slice.reducer;
