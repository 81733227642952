import React from "react";

const SVG = ({
    style = {},
    color = '#adb5b8',
    width = '108px',
    className = '',
    height = '108px',
    viewBox = '0 0 108 108'
  }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox={viewBox}
    >
    <path
        fill="#d6d6d6"
        d="M15.82 15.82H92.19V92.19H15.82z"
        transform="rotate(45 54.003 53.999)"
      ></path>
      <path
        fill="#e5e3e4"
        d="M23.07 18.82H93.43V89.18H23.07z"
        opacity="0.46"
        transform="rotate(45 58.244 53.996)"
      ></path>
      <g>
        <path
          fill="#ff8b59"
          d="M76.92 50.51L53.43 26.76l-23 23.75 23.23 21.85zm0 0"
        ></path>
        <path
          fill="#dceaff"
          d="M70.16 65.9H37.37A2.86 2.86 0 0134.5 63V29.63a2.86 2.86 0 012.86-2.86h32.8A2.86 2.86 0 0173 29.63V63a2.86 2.86 0 01-2.86 2.86zm0 0"
        ></path>
        <path
          fill="#fb3340"
          d="M77.1 50.92V74.3a2.93 2.93 0 01-2.93 2.93h-41a2.93 2.93 0 01-2.93-2.93V50.93a.56.56 0 01.84-.48l22.1 12.87h.09a1 1 0 00.88 0l1-.6 21.12-12.28a.55.55 0 01.83.48zm0 0"
        ></path>
        <path
          fill="#ffcb57"
          d="M58.19 40.52h-8.85a.89.89 0 01-.89-.89v-5.78a.89.89 0 01.89-.89h8.85a.89.89 0 01.89.89v5.78a.89.89 0 01-.89.89zm0 0"
        ></path>
        <path
          fill="#3d3d3f"
          d="M58.64 55.35h-9.76a.76.76 0 000 1.52h9.75a.76.76 0 000-1.52zm0 0M48.71 50.21v-2.16h1.38a.76.76 0 100-1.52h-1.38v-1.29h1.56a.76.76 0 100-1.52h-2.32a.76.76 0 00-.76.76v5.73a.76.76 0 101.52 0zm0 0M56.19 45.24a.76.76 0 100-1.52h-2.32a.76.76 0 00-.76.76v5.73a.76.76 0 101.52 0v-2.16H56a.76.76 0 000-1.52h-1.37v-1.29zm0 0M61.77 45.19a.76.76 0 100-1.52h-2.41a.76.76 0 00-.76.76v5.72a.76.76 0 00.76.76h2.41a.76.76 0 100-1.52h-1.65v-1.34h1.47a.76.76 0 100-1.52h-1.47v-1.34zm0 0M69.47 45.9a2.31 2.31 0 00-2.35-2.26h-1.54a.76.76 0 00-.76.76v5.78a.76.76 0 001.52 0v-1.53l1.79 2a.76.76 0 001.14-1l-1.43-1.62a2.28 2.28 0 001.62-2.15zm-2.35.74h-.77v-.74-.74h.77a.74.74 0 110 1.48zm0 0M41.51 43.69a3.65 3.65 0 103.65 3.65 3.65 3.65 0 00-3.65-3.65zm0 5.78a2.13 2.13 0 112.13-2.13 2.13 2.13 0 01-2.13 2.13zm0 0"
        ></path>
        <path
          fill="#3f3a34"
          d="M77.43 50l-3.65-3.69V29.63A3.63 3.63 0 0070.16 26H37.37a3.63 3.63 0 00-3.63 3.63V46l-3.87 4a1.3 1.3 0 00-.35.9v23.4a3.7 3.7 0 003.7 3.7h41a3.7 3.7 0 003.7-3.7V50.92a1.3 1.3 0 00-.42-1zm-2 .11l-1.65.89v-2.57zm-41.69 1.01l-1.82-1.06 1.82-1.88zM76.33 74.3a2.17 2.17 0 01-2.17 2.17h-41A2.17 2.17 0 0131 74.3v-23L52.8 64l16.76 9.76a.764.764 0 00.77-1.32L55.18 63.6l13.42-7.81a.764.764 0 00-.77-1.32l-14.07 8.19a.2.2 0 01-.2 0l-1.48-.86L35.26 52V29.63a2.11 2.11 0 012.1-2.1h32.8a2.1 2.1 0 012.1 2.1v22.26l-1.74 1a.764.764 0 10.77 1.32L73.38 53l2.94-1.71zm0 0"
        ></path>
        <path
          fill="#3d3d3f"
          d="M49.34 41.28h8.85a1.66 1.66 0 001.65-1.65v-5.78a1.66 1.66 0 00-1.65-1.65h-1.9v-.81a1.79 1.79 0 00-1.79-1.79H53a1.79 1.79 0 00-1.79 1.79v.81h-1.87a1.66 1.66 0 00-1.65 1.65v5.78a1.66 1.66 0 001.65 1.65zm3.42-9.9a.27.27 0 01.27-.27h1.47a.27.27 0 01.27.27v.81h-2zm-3.55 2.47a.13.13 0 01.13-.13h8.85a.13.13 0 01.13.13v5.78a.13.13 0 01-.13.13h-8.85a.13.13 0 01-.13-.13zm0 0"
        ></path>
      </g>
  </svg>
  );
}

export default SVG;
