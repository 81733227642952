import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'modal',
  initialState: {
    showModal: false,
    modalName: null,
    hasCloseButton: true,
    params: {},
    size: 'medium',
  },
  reducers: {
    modalShow: (modal, action) => {
      const {
        params = {},
        hasCloseButton = true,
        modalName,
        size = 'medium',
      } = action.payload;

      console.log(action.payload);
      modal.showModal = true;
      modal.modalName = modalName;
      modal.hasCloseButton = hasCloseButton;
      modal.params = params;
      modal.size = size;
    },
    closeModal: (modal, action) => {
      modal.showModal = false;
      modal.modalName = null;
      modal.params = {};
    },
  },
});
export const { modalShow, closeModal } = slice.actions;

export default slice.reducer;
