import React from 'react';

const SVG = ({
    style = {},
    color = '#adb5b8',
    width = '18px',
    className = '',
    height = '18px',
    viewBox = '0 0 18 18'
  }) =>
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>LinkedIn</title>
      <symbol id="icn-in" viewBox="0 0 31.474 30.022">
            <path d="M31.474,18.406v11.616h-6.746V19.184c0-2.722-0.976-4.58-3.417-4.58c-1.864,0-2.974,1.252-3.462,2.464 	c-0.178,0.433-0.224,1.036-0.224,1.642v11.313h-6.748c0,0,0.091-18.354,0-20.257h6.747v2.87c-0.013,0.022-0.03,0.046-0.044,0.066 	h0.044v-0.066c0.897-1.377,2.498-3.346,6.082-3.346C28.146,9.29,31.474,12.184,31.474,18.406z M3.818,0C1.51,0,0,1.513,0,3.498 	c0,1.946,1.466,3.503,3.729,3.503h0.045c2.354,0,3.817-1.557,3.817-3.503C7.546,1.513,6.127,0,3.818,0z M0.4,30.022h6.745V9.766H0.4 	V30.022z" /> </symbol>
    </svg>
  
  export default SVG
  