import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'snackbar',
  initialState: {
    showSnack: false,
    message: '',
    timeout: 4000,
    showAction: true,
  },
  reducers: {
    alertShow: (s, { payload }) => {
      if (typeof payload !== 'undefined') {
        const {
          message = '',
          timeout = 4000,
          closeType = 'icon',
          showAction = true,
        } = payload;
        s.showSnack = true;
        s.message = message;
        s.timeout = timeout;
        s.closeType = closeType;
        s.showAction = showAction;
      }
    },
    alertClear: (snackbar, action) => {
      snackbar.showSnack = false;
      snackbar.message = '';
    },
  },
});
export const { alertShow, alertClear } = slice.actions;

export default slice.reducer;
