import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

const CustomLink = ({
  className,
  to,
  icon = null,
  target = 'self',
  text,
  activeOnlyWhenExact,
}) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  if (target !== 'self') {
    return (
      <a
        href={to}
        target={target}
        className={`${match ? 'active' : ''} ${className}${
          icon !== null ? ' has-icon' : ''
        }`}
      >
        {icon} {text}
      </a>
    );
  } else {
    return (
      <div
        className={`${match ? 'active' : ''} ${className}${
          icon !== null ? ' has-icon' : ''
        }`}
      >
        <Link to={to}>
          {icon} {text}
        </Link>
      </div>
    );
  }
};

const LinkClick = ({
  id,
  text,
  to,
  icon = null,
  onClick,
  className,
  activeOnlyWhenExact,
}) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <div
      className={`${match ? 'active' : ''} ${className}${
        icon !== null ? ' has-icon' : ''
      }`}
    >
      <Link
        id={id}
        to="#"
        className={`${className}`}
        onClick={(ev) => onClick(ev)}
      >
        {icon} {text}
      </Link>
    </div>
  );
};

export { CustomLink, LinkClick };
export default CustomLink;
