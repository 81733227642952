import { createSlice } from '@reduxjs/toolkit';
// import moment from 'moment';
import { apiCallBegan } from '../actions/api';
// import { loadState, saveState } from '../localstore';

const slice = createSlice({
  name: 'nav',
  initialState: {
    data: [],
    isFetching: false,
    lastFetchTimeStamp: null,
    isFetchedSuccess: false,
  },
  reducers: {
    navRequestBegan: (nav, action) => {
      nav.isFetching = true;
      nav.type = action.propType;
    },
    nav: (nav, action) => {
      const { data } = action.payload;
      nav.data = data;
    },
    navRequestFailed: (nav, action) => {
      nav.isFetching = false;
      nav.isFetchedSuccess = false;
    },
    navRequestCompleted: (nav, action) => {
      nav.isFetchedSuccess = true;
      nav.isFetching = false;
    },
  },
});

export const {
  navRequestBegan,
  nav,
  navRequestFailed,
  navRequestCompleted,
} = slice.actions;
export default slice.reducer;

export const loadNavs = () => async (dispatch, getState) => {
  const s = getState().ui.Navbar.isFetchedSuccess;
  if (!s) {
    dispatch(
      apiCallBegan({
        url: 'navbar/large',
        onStart: navRequestBegan.type,
        onSuccess: nav.type,
        onError: navRequestFailed.type,
        onCompleted: navRequestCompleted.type,
      })
    );
  }
};
