import React from 'react';

const SVG = ({
  style = {},
  color = '#adb5b8',
  width = '25px',
  className = '',
  height = '25px',
  viewBox = '0 0 24 24'
}) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Twitter</title>
    <path d="M.473 19.595a13.928 13.928 0 007.478 2.155c3.91 0 7.493-1.502 10.09-4.229 2.485-2.61 3.852-6.117 3.784-9.676.942-.806 2.05-2.345 2.05-3.845a.75.75 0 00-1.13-.647c-.885.52-1.692.656-2.522.423-1.695-1.652-4.218-2-6.344-.854-1.858 1-2.891 2.83-2.798 4.83A12.427 12.427 0 013.02 3.349a.75.75 0 00-1.226.1C.82 5.117.83 7.05 1.677 8.611a.78.78 0 00-.652.777 5.29 5.29 0 001.843 3.995.749.749 0 00-.192.777 5.384 5.384 0 003.047 3.264 8.732 8.732 0 01-4.756.794c-.784-.106-1.171.948-.494 1.377zm7.683-1.914c.561-.431.263-1.329-.441-1.344a3.912 3.912 0 01-3.072-1.598 5.29 5.29 0 001.024-.164c.761-.206.725-1.304-.048-1.459a3.85 3.85 0 01-2.917-2.62c.377.093.761.145 1.144.152.759.004 1.046-.969.427-1.376-1.395-.919-1.99-2.542-1.596-4.068a13.943 13.943 0 009.237 4.123.749.749 0 00.767-.917c-.475-2.059.675-3.502 1.91-4.167 1.222-.66 3.184-.866 4.688.712.447.471 1.955.489 2.722.31-.344.648-.873 1.263-1.368 1.609a.75.75 0 00-.319.651c.161 3.285-1.063 6.551-3.358 8.96-2.312 2.427-5.509 3.764-9.004 3.764-1.39 0-2.753-.226-4.041-.662 1.54-.298 3.003-.95 4.245-1.906z" />
  </svg>

export default SVG
