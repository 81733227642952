import React from "react";


const Button = (props) => {
  const { className, onClick, text = '', icon = null, disabled = false, variant = 'Basic', color = "black", border = "none" } = props
  const onClickButton = (ev) => {
    if (disabled) return;
    onClick(ev)
  }

  return (
    <button
      className={`btn ${className}`}
      onClick={ev => onClickButton(ev)}
    >
      {icon} {text}
    </button>
  );
};


// const toggleButton = ({ types }) => {
//   const [active, setActive] = useState(types[0]);
//   return (
//     <Button>
//       {types.map(type => (
//         <ButtonToggle
//           key={type}
//           active={active === type}
//           onClick={() => setActive(type)}
//         >
//           {type}
//         </ButtonToggle>
//       ))}
//     </Button>
//   )
// }

export default Button;