import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiCallBegan } from '../actions/api';

const slice = createSlice({
  name: 'routes',
  initialState: {
    routes: [],
    isFetching: false,
    lastFetchTimeStamp: null,
    routeFetchedSuccess: false,
    routeFetchRequired: true,
  },
  reducers: {
    routesRequestBegan: (routes, action) => {
      routes.isFetching = true;
    },
    routes: (routes, action) => {
      const { data } = action.payload;
      routes.routes = data;
      var d = new Date();
      var n = d.getTime();
      routes.lastFetchTimeStamp = n;
    },
    routesRequestFailed: (routes, action) => {
      routes.isFetching = false;
      routes.routeFetchRequired = true;
      routes.routeFetchedSuccess = false;
    },
    routesRequestCompleted: (routes, action) => {
      routes.isFetching = false;
      routes.routeFetchRequired = false;
      routes.routeFetchedSuccess = true;
      routes.lastFetchTimeStamp = Date.now();
    },
  },
});

export const {
  routesRequestBegan,
  routes,
  routesRequestFailed,
  routesRequestCompleted,
} = slice.actions;
export default slice.reducer;

export const loadRoutes = () => async (dispatch, getState) => {
  const { lastFetchTimeStamp } = getState().ui.Routes;
  if (lastFetchTimeStamp != null) {
    const diffInMinutes = moment().diff(moment(lastFetchTimeStamp), 'minutes');
    if (diffInMinutes < 60) return;
  }

  dispatch(
    apiCallBegan({
      url: 'routes',

      onStart: routesRequestBegan.type,
      onSuccess: routes.type,
      onCompleted: routesRequestCompleted.type,
      onError: routesRequestFailed.type,
    })
  );
};
