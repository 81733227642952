import React from "react";

const SVG = ({
    style = {},
    color = '#adb5b8',
    width = '108px',
    className = '',
    height = '108px',
    viewBox = '0 0 108 108'
  }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox={viewBox}
    >
    <path
        fill="#c3d3dd"
        d="M19.52 100h-8.76A8.77 8.77 0 012 91.24V25.5h8.77V12.75h8.77zm0 0"
      ></path>
      <path
        fill="#fb3340"
        d="M29.09 39.05H59.769999999999996V77.3H29.09z"
      ></path>
      <path fill="#ffcb57" d="M29.09 15.14H90.45V29.48H29.09z"></path>
      <path
        fill="#3f3a34"
        d="M100 0H19.52a2 2 0 00-2 2v8.77h-6.76a2 2 0 00-2 2v10.74H2a2 2 0 00-2 2v65.73A10.77 10.77 0 0010.76 102h80.48A10.77 10.77 0 00102 91.24V2a2 2 0 00-2-2zm0 91c0 3.74-4.26 9-8 9H16c1.5-1.85 4-6.44 4-9V64c0-1.1.1-2-1-2s-1.47 1.25-1.47 2.35v26.89C17.53 95 13.74 99 10 99s-8-4.26-8-8V26h8v63c0 1.1-.1 2 1 2s1-.9 1-2V13h6l-.47 33.42c0 1.1.37 1.58 1.47 1.58s1-.9 1-2V2h80zm-2 .24"
      ></path>
      <path
        fill="#3f3a34"
        d="M29.09 31.48h61.36a2 2 0 002-2V15.14a2 2 0 00-2-2H29.09a2 2 0 00-2 2v14.34a2 2 0 002 2zM29 15h62v14H29zm.08.13M29.09 79.29h30.68a2 2 0 002-2V39a2 2 0 00-2-2H29.09a2 2 0 00-2 2v38.3a2 2 0 002 2zM29 39h31v38H29zm2.08 2M90.45 37.05H69.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M90.45 50.91H69.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M90.45 58H69.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M90.45 65H69.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M90.45 72.06H69.33c-1.1 0-2 .66-2 1.47s.89 1.47 2 1.47h21.12c1.1 0 2-.66 2-1.47s-.89-1.47-2-1.47zm0 0M29.09 87h61.36c1.1 0 2-.48 2-1.07s-.89-1.07-2-1.07H29.09c-1.1 0-2 .48-2 1.07S28 87 29.09 87zm0 0"
      ></path>
      <path
        fill="#3b3b3b"
        d="M19.62 53.39A2 2 0 1021 54a2 2 0 00-1.41-.58zm0 0"
      ></path>
  </svg>
  );
}

export default SVG;
