import React from 'react';

const SVG = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 104.29 103.74"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a" transform="translate(-1.71 -3.26)">
          <path fill="none" d="M1.71 3H106v104H1.71z" />
        </clipPath>
      </defs>
      <title>{'paymentsIcon'}</title>
      <g clipPath="url(#prefix__a)">
        <path
          d="M102.55 25.3h-1.74v-7a1.74 1.74 0 00-2-1.73l-1.52.23V9.62a1.72 1.72 0 00-.68-1.38 1.76 1.76 0 00-1.49-.31l-63.71 16.4 6.48-11.26a8.85 8.85 0 001.15-4.33 8.69 8.69 0 00-16.22-4.39L1.18 41.97A8.7 8.7 0 000 46.34V67.9a8.59 8.59 0 00.29 2.39l5.54 19.5h-.62a1.74 1.74 0 00-1.73 1.75V102a1.74 1.74 0 001.73 1.74h38.24a1.74 1.74 0 001.74-1.74V91.54a1.74 1.74 0 00-1.74-1.74v-4.38a12.22 12.22 0 00-3.56-8.68l-2.68-2.67h65.34a1.74 1.74 0 001.74-1.7V27.05a1.74 1.74 0 00-1.74-1.74zm-5.21-5v5H59.57zm-3.48-8.45v5.4l-43.12 5.7zm-52.14 88.4H6.95v-7h34.77zM31.43 73.03a1.59 1.59 0 00.38.56l5.63 5.65A8.63 8.63 0 0140 85.41v4.37H9.49L3.67 69.35a5.78 5.78 0 01-.19-1.45V46.34a5.08 5.08 0 01.71-2.6L25.85 6.1a5.21 5.21 0 019.71 2.64 5.24 5.24 0 01-.7 2.6l-8 14H22.6a1.74 1.74 0 00-1.74 1.74v36.6a1.77 1.77 0 001.08 1.62 1.74 1.74 0 001.91-.42l15.33-15.9a5.22 5.22 0 018.81 2.7c0 .35.07.7.09 1a5.36 5.36 0 01-1.53 3.7L31.79 71.13a1.75 1.75 0 00-.36 1.9zm69.39-2.41H37.21l11.08-11.05a17.2 17.2 0 0014.28 7.57 17.43 17.43 0 10-16.58-22.61 8.73 8.73 0 00-9.3 2l-12.4 12.82V28.79h76.48zM51.41 51.1a8.78 8.78 0 00-2.36-4.57 14 14 0 111.49 10.09 9.39 9.39 0 001-4c0-.47-.07-.92-.14-1.48zm0 0"
          fill="#3f3a34"
        />
      </g>
      <path
        d="M62.57 54.94a1.74 1.74 0 01-1.74-1.74h-3.47a5.23 5.23 0 003.48 4.91v2.06h3.45V58.1a5.22 5.22 0 00-1.74-10.14 1.74 1.74 0 111.74-1.74h3.5a5.23 5.23 0 00-3.5-4.91v-2.06h-3.45v2.06a5.22 5.22 0 001.74 10.14 1.745 1.745 0 010 3.49zm0 0"
        fill="teal"
      />
      <path
        d="M31.29 39.03a7 7 0 005-5h3.7v-3.5h-5.23a1.74 1.74 0 00-1.74 1.74 3.48 3.48 0 01-3.47 3.47 1.74 1.74 0 00-1.74 1.74v5.26h3.48zm0 0M93.86 40.74v3.71h3.43v-5.2a1.74 1.74 0 00-1.74-1.74 3.48 3.48 0 01-3.48-3.49 1.74 1.74 0 00-1.74-1.74h-5.16v3.46h3.7a7 7 0 005 5zm0 0M92.12 65.39a3.48 3.48 0 013.48-3.49 1.74 1.74 0 001.69-1.74v-5.22h-3.43v3.7a7 7 0 00-5 5h-3.7v3.5h5.21a1.74 1.74 0 001.74-1.74zm0 0"
        fill="#00bcb7"
      />
    </svg>
  );
};

export default SVG;
