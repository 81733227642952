import React, { Fragment, useState } from 'react';
import LogoSVG from '../../Logo/index';
import { CustomLink, LinkClick } from './../../Links/Link';
import Icons from '../../Icons';
import useSticky from '../../../hooks/useSticky';

import { MODAL_NAMES } from '../../Modals/ModalContainer';
import { modalShow } from '../../../store/utils/modals';
import { useDispatch } from 'react-redux';

const Burger = ({ isOpen, direction = null }) => {
  return (
    <div className={`burger burger-slide ${isOpen ? 'open' : ''}`}>
      <div className="burger-lines" />
    </div>
  );
};

const MobileNavBar = () => {
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);
  const { isSticky, element } = useSticky();

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(modalShow({ modalName: MODAL_NAMES['FreeConsultation'] }));
  };

  return (
    <Fragment>
      <div
        className={`mobile-topbar ${isSticky ? 'sticky' : ''}`}
        ref={element}
      >
        <div className="mobile-topbar-logo">
          <LogoSVG width="150" height="25" to="/" />
        </div>
        <div className="topbar-links">
          {/* <LinkClick text="Login" onClick={handleClick} />
          &nbsp; | &nbsp;
          <LinkClick text="Signup" onClick={handleClick} /> */}
        </div>
      </div>
      <div className={`mobile-navbar ${isExpand ? 'expand-menu-view' : ''}`}>
        <div className="mobile-links">
          <div className="primary-links">
            <div className="link-icons">
              <CustomLink
                text="Furniture"
                icon={React.createElement(Icons['FurnitureIcon'])}
                to="/furniture"
                className="nav-icon-grid"
                activeOnlyWhenExact={true}
              />
            </div>
            <div className="link-icons">
              <CustomLink
                text="Design"
                icon={React.createElement(Icons['DesignIcon'])}
                to="/designs"
                activeOnlyWhenExact={true}
              />
            </div>
            <div className="link-icons">
              <LinkClick
                text="Talk to us"
                icon={React.createElement(Icons['TalkToUsIcon'])}
                to="#"
                onClick={handleClick}
                activeOnlyWhenExact={true}
              />
            </div>
            <div className="link-icons">
              <CustomLink
                text="Magzine"
                icon={React.createElement(Icons['MagzineNavIcon'])}
                to="/magzine"
                activeOnlyWhenExact={true}
              />
            </div>

            <div className="link-icons">
              <LinkClick
                text={isExpand ? 'Close' : 'More'}
                icon={<Burger isOpen={isExpand} />}
                onClick={() => setIsExpand((v) => !v)}
                activeOnlyWhenExact={true}
              />
            </div>
          </div>
          <div className={`secondary-links ${isExpand ? '' : 'hide'}`}>
            <div className="link">
              <CustomLink
                text="About us"
                icon={null}
                to="/about-us"
                activeOnlyWhenExact={true}
              />
            </div>
            <div className="link">
              <CustomLink
                text="Refer a friend"
                icon={null}
                to="/refer-a-friend"
                activeOnlyWhenExact={true}
              />
            </div>
            <div className="link">
              <CustomLink
                text="Faq"
                icon={null}
                to="/faq"
                activeOnlyWhenExact={true}
              />
            </div>
            <div className="link">
              <CustomLink
                text="Help 24x7"
                icon={null}
                to="/help24x7"
                activeOnlyWhenExact={true}
              />
            </div>
            <div className="link">
              <CustomLink
                text="Careers"
                icon={null}
                to="/careers"
                activeOnlyWhenExact={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MobileNavBar;
