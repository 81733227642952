import React from 'react';

const SVG = (props) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 105.95 100.37"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a" transform="translate(-1.34 -3.62)">
          <path fill="none" d="M.67 4h106.67v100H.67z" />
        </clipPath>
      </defs>
      <title>{'homeIcon'}</title>
      <path
        d="M94.33 40.38v50.41a6.25 6.25 0 01-6.25 6.25H17.24a6.25 6.25 0 01-6.25-6.25V40.38L44.12 7.25a12.08 12.08 0 0117.09 0l33.12 33.13zm0 0"
        fill="teal"
      />
      <path
        className="prefix__683ad6c2-956a-4124-b2d8-49196566d86e"
        d="M46.41 60.38h12.5a7.08 7.08 0 017.08 7.08v29.58H39.33V67.46a7.08 7.08 0 017.08-7.08zm0 0"
        fill="#e2e0e0"
      />
      <path
        className="prefix__3ef98d21-b0db-4c61-a9e6-f64f43690afd"
        d="M94.33 40.38v50.41a6.25 6.25 0 01-6.25 6.25h-2.81a6.2 6.2 0 00.72-2.92V43.71L52.87 10.58a12 12 0 00-8.54-3.54 12.08 12.08 0 0116.88.21zm0 0"
        fill="#00bcb7"
      />
      <g
        className="prefix__3dc4312c-2ede-4d90-b462-4396192bbbf1"
        clipPath="url(#prefix__a)"
      >
        <path
          className="prefix__64dcda47-570c-4c4e-9e47-585d60b3a098"
          d="M67.06 66.38v29.6h-8.33v-29.6a7.08 7.08 0 00-7.07-7.07h8.33a7.08 7.08 0 017.07 7.07zm0 0"
          fill="#cecccc"
        />
        <path
          d="M103.86 46.11L61.19 4.51c-6-6-11.06-6-17.07 0L3.59 46.11C2.29 47.38-1 49.76.3 51.06a3.33 3.33 0 004.71 0l2.65-2.68v42.41a9.59 9.59 0 009.58 9.59h70.84a9.59 9.59 0 009.58-9.59V48.38l2.64 2.64a3.33 3.33 0 004.71 0c1.3-1.3 1.21-2.58-.09-3.88zm-64 48V67.45c0-2.07 3.27-6.4 5.33-6.4h16c2.07 0 4.27 4.33 4.27 6.4v26.66zm54.4-3.2c0 1.61-3.73 5.33-5.33 5.33h-19.2l-.4-28.79a10.43 10.43 0 00-10.42-10.41h-12.5a10.43 10.43 0 00-10.42 10.42l-.4 28.79h-19.2c-1.61 0-5.33-1.59-5.33-3.2l1.07-51.2L45.19 7.71C48.6 4.3 55.66 3.23 59.06 6.65l35.2 35.2zm-3.27-.12"
          fill="#3f3a34"
        />
      </g>
    </svg>
  );
};

export default SVG;
