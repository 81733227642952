import React from 'react';

const SVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 372 304.6"
    {...props}
    height={25}
    width={25}
  >
    <title>{'magazine'}</title>
    <path d="M192 251.16V72a6 6 0 00-12 0v179.16a6 6 0 0012 0zm0 0" />
    <path d="M366 26.92c-12.68 0-28.49-5.47-45.23-11.27C299.58 8.32 275.56 0 250.64 0c-28.83 0-46.42 12.63-56.09 23.23A73.29 73.29 0 00186 34.69a72.78 72.78 0 00-8.55-11.46C167.77 12.63 150.19 0 121.36 0 96.45 0 72.42 8.32 51.23 15.66 34.5 21.46 18.69 26.93 6 26.93a6 6 0 00-6 6V274.6a6 6 0 006 6c14.7 0 31.44-5.79 49.15-11.93 20.3-7 43.31-15 66.2-15 19.63 0 35.52 6.5 47.23 19.32a6 6 0 008.86-8.09c-9.68-10.6-27.27-23.23-56.09-23.23-24.91 0-48.93 8.32-70.13 15.66-14.22 4.91-27.7 9.59-39.22 10.91V38.63C25.19 37.37 39.8 32.31 55.15 27c20.3-7 43.31-15 66.2-15 46.31 0 58.42 36.77 58.91 38.32a6 6 0 0011.47 0c.12-.38 12.31-38.32 58.91-38.32 22.89 0 45.9 8 66.2 15 15.35 5.32 30 10.37 43.15 11.63v229.61c-11.52-1.31-25-6-39.23-10.91-21.2-7.34-45.21-15.66-70.13-15.66-28.83 0-46.42 12.63-56.09 23.23a6 6 0 108.86 8.09c11.71-12.82 27.6-19.32 47.24-19.32 22.89 0 45.9 8 66.2 15 17.72 6.13 34.46 11.93 49.16 11.93a6 6 0 006-6V32.92a6 6 0 00-6-6zm0 0" />
    <path d="M366 292.6c-12.68 0-28.49-5.47-45.23-11.27-21.2-7.34-45.21-15.66-70.13-15.66-27.07 0-44.24 11.14-54.24 21.28h-20.81c-10-10.13-27.16-21.28-54.24-21.28-24.91 0-48.93 8.32-70.13 15.66C34.49 287.12 18.68 292.6 6 292.6a6 6 0 000 12c14.7 0 31.44-5.79 49.15-11.93 20.3-7 43.31-15 66.2-15 19.63 0 35.52 6.5 47.23 19.32a6 6 0 004.43 2h26a6 6 0 004.43-2c11.71-12.82 27.6-19.32 47.23-19.32 22.9 0 45.91 8 66.21 15 17.68 6.13 34.42 11.93 49.12 11.93a6 6 0 000-12zm0 0" />
  </svg>
);

export default SVG;
