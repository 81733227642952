import React from 'react';

const SVG = () =>
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox='0 0 720 812'>
     <title>paper_launch2</title>
    <g>
        <path transform="translate(0 0)" style={{fill: 'none', isolation: 'isolate'}}/>
        <path d="M419.3,394.7,273.2,625.35,27.32,590.87l19-17.83Z" transform="translate(0 0)" style={{fill: '#3ecadd'}}/>
        <path d="M415.73,400.65,668.74,0,48.7,571.85Z" transform="translate(0 0)" style={{fill: '#fee038'}}/>
        <path d="M273.2,625.35,668.74,0,374.16,636l59.39,15.45L361.09,757.31Z" transform="translate(0 0)" style={{fill: '#10a2b6'}}/>
        <path d="M668.74,0l5.94,693.11L488.19,387.57Z" transform="translate(0 0)" style={{fill: '#fee038'}}/>
        <path d="M488.19,386.38,674.67,693.11v27.34L371.78,636Z" transform="translate(0 0)" style={{fill: '#3ecadd'}}/>
        <path d="M371.78,636,361.09,756.12l71.27-103.43Z" transform="translate(0 0)" style={{fill: '#098fa1'}}/>
    </g>
  </svg>
  
  export default SVG
  