import React from "react";
import { Link } from "react-router-dom";

const HeaderLink = (props) => {
  const { className, to, icon = null, text, target = 'self', type = 'Link' } = props
  if (type === 'Link') {
    return (
      <Link to={to} className={`link ${className}${icon !== null ? ' has-icon' : ''}`}>
        {icon} {text}
      </Link>
    )
  } else {
    return (
      <a href={to} target={target} className={`link ${className}${icon !== null ? ' has-icon' : ''}`}>
        {icon} {text}
      </a>
    )
  }

}

const CustomLink = props => {
  return (
    <Link to={props.to} className={`link ${props.className}${props.icon !== null ? ' has-icon' : ''}`}>
      {props.icon} {props.text}
    </Link>
  )
}

const LinkClick = ({ id, text, icon = null, onClick, className }) => {

  return (
    <Link
      id={id}
      to='#'
      className={`${className}`}
      onClick={ev => onClick(ev)}
    >
      {icon} {text}
    </Link>
  );
};

export { LinkClick, CustomLink };


export default HeaderLink


