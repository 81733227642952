import React from 'react';
import LinkClick from '../../Links/Link';
import ModalSmall from '../ModalSmall';
import ModalLarge from '../ModalLarge';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import useForm from './../../../hooks/useForm';
import { postData } from '../../../store/post/postData';

const RenderForm = (props) => {
  const dispatch = useDispatch();
  const { values, useInput, isValid } = useForm({
    full_name: '',
    phone: '',
    email: '',
    property_name: '',
    pincode: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postData(values));
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-input">
        <TextField
          id="standard-error-helper-tex"
          label="Name"
          {...useInput('full_name', 'isRequired', 'Name is required')}
          size="small"
          autoComplete="x"
          className="w100"
          color="primary"
        />
      </div>
      <div className="modal-input">
        <TextField
          id="standard-error-helper-tex"
          label="Contact No."
          {...useInput(
            'phone',
            {
              isMobilePhone: 'en-IN',
              isRequired: true,
            },
            'We need your mobile no to reach you'
          )}
          size="small"
          autoComplete="x"
          className="w100"
          color="primary"
        />
      </div>
      <div className="modal-input">
        <TextField
          id="standard-error-helper-tex"
          label="Email"
          {...useInput(
            'email',
            {
              isEmail: {},
              isRequired: true,
            },
            'We need your email to share information with you'
          )}
          size="small"
          className="w100"
          autoComplete="email"
          color="primary"
        />
      </div>
      <div className="modal-input">
        <TextField
          id="standard-error-helper-tex"
          label="Property Name"
          {...useInput('property_name')}
          size="small"
          className="w100"
          autoComplete="x"
          color="primary"
        />
      </div>
      <div className="modal-input">
        <TextField
          id="standard-error-helper-tex"
          label="Pincode"
          {...useInput(
            'pincode',
            {
              isPostalCode: 'IN',
              isRequired: true,
            },
            'We need pincode to locate the area.'
          )}
          size="small"
          className="w100"
          autoComplete="x"
          color="primary"
        />
      </div>
      <Button
        type="submit"
        disabled={!isValid}
        color="primary"
        variant="contained"
      >
        Submit
      </Button>
    </form>
  );
};

const FreeConsultation = (props) => {
  const { isMobile } = useSelector((state) => state.utils.Info.device);
  return isMobile ? (
    <ModalSmall>{RenderForm()}</ModalSmall>
  ) : (
    <ModalLarge
      title="Login"
      subtitle="Get Access to your Orders, Transactions and much more..."
      icon=""
    >
      {RenderForm()}
      <div className="modal-createAccount">
        <label>New to Houzspace?</label>
        <LinkClick
          text="Create an account"
          onClick={() => console.log('clicked')}
          className="primary"
        />
      </div>
    </ModalLarge>
  );
};

export default FreeConsultation;
