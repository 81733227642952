import React from "react";

const SVG = ({
    style = {},
    color = '#adb5b8',
    width = '108px',
    className = '',
    height = '108px',
    viewBox = '0 0 108 108'
  }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox={viewBox}
    >
    <defs>
        <clipPath id="loginIcon" transform="translate(-2 -4)">
          <path fill="none" d="M2 4H104V108H2z"></path>
        </clipPath>
      </defs>
      <path
        fill="#ca1f43"
        d="M37.19 13A12.89 12.89 0 0149.94 0h42.5a4.23 4.23 0 013.5 1.88 4.4 4.4 0 01.47 4L65.59 88.21a4.25 4.25 0 01-4 2.79H49.94a12.89 12.89 0 01-12.75-13zm0 0"
      ></path>
      <g clip-path="url(#loginIcon)">
        <path
          fill="#ee3944"
          d="M62.69 17.33v78a5.37 5.37 0 005.31 5.42 5.57 5.57 0 001.7-.26l25.5-8.67a5.48 5.48 0 003.61-5.16v-78a5.37 5.37 0 00-5.31-5.41 5.57 5.57 0 00-1.7.26l-25.5 8.67a5.48 5.48 0 00-3.61 5.16zm0 0"
        ></path>
        <path
          fill="#64b5f6"
          d="M59.5 17.33a8.7 8.7 0 015.75-8.22L90.79.43A8.59 8.59 0 01102 8.67v78a8.7 8.7 0 01-5.75 8.22l-25.53 8.68A8.81 8.81 0 0168 104a8.59 8.59 0 01-8.5-8.67m34-88.83a2.4 2.4 0 00-.76.12l-25.43 8.64a2.22 2.22 0 00-1.44 2.07v78a2.22 2.22 0 002.89 2.05l25.43-8.65a2.22 2.22 0 001.44-2.07v-78A2.15 2.15 0 0093.5 6.5zm0 0"
        ></path>
      </g>
      <path
        fill="#3f3a34"
        d="M43.56 48.75H3.19a3.25 3.25 0 010-6.5h40.37a3.25 3.25 0 010 6.5zm0 0"
      ></path>
      <g fill="#3f3a34" clip-path="url(#a)">
        <path d="M27.63 65a3.14 3.14 0 01-2.25-1 3.3 3.3 0 010-4.6l13.69-14-13.7-13.85a3.3 3.3 0 010-4.6 3.15 3.15 0 014.51 0L45.82 43.2a3.3 3.3 0 010 4.6L29.88 64a3.15 3.15 0 01-2.26 1zm0 0M68 104a8.59 8.59 0 01-8.5-8.67v-78a8.71 8.71 0 015.75-8.23L90.79.42A8.62 8.62 0 01102 8.67v78a8.71 8.71 0 01-5.75 8.22l-25.54 8.68A8.74 8.74 0 0168 104zM93.5 6.5a2.45 2.45 0 00-.76.11l-25.43 8.65a2.23 2.23 0 00-1.44 2.08v78a2.24 2.24 0 002.89 2.05l25.43-8.64a2.24 2.24 0 001.44-2.08v-78A2.15 2.15 0 0093.5 6.5zm0 0"></path>
      </g>
      <path
        fill="#3f3a34"
        d="M37.19 21.67A3.22 3.22 0 0134 18.42v-6.5A11.81 11.81 0 0145.69 0H93.5a3.25 3.25 0 010 6.5H45.69a5.37 5.37 0 00-5.31 5.42v6.5a3.22 3.22 0 01-3.19 3.25zm0 0M62.69 91h-17A11.81 11.81 0 0134 79.08v-6.5a3.19 3.19 0 116.38 0v6.5a5.37 5.37 0 005.31 5.42h17a3.25 3.25 0 010 6.5zm0 0"
      ></path>
  </svg>
  );
}

export default SVG;
