import React from 'react';

const SVG = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108" {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path fill="none" d="M17.33 9.78H89v72.9H17.33z" />
        </clipPath>
      </defs>
      <title>{'branded_materials_sofa'}</title>
      <path fill="#d6d6d6" d="M54 .003l54.002 54.002L54 108.006-.002 54.005z" />
      <path
        fill="#e5e3e4"
        opacity={0.46}
        d="M58.245 4.25l49.752 49.753-49.752 49.752L8.493 54.003z"
      />
      <g>
        <path
          d="M88.06 63.11v13.4H19.67v-13.4a4.83 4.83 0 014.74-4.92h.47a4.86 4.86 0 014.26 4.9v3.56h49.45v-3.54a4.86 4.86 0 014.26-4.9h.47a4.83 4.83 0 014.74 4.92zm0 0"
          fill="teal"
        />
        <path
          d="M73.3 22.52l2.95 10.2H62.17l2.95-10.2zm0 0M70.55 53.81a2.43 2.43 0 01.45 2.57 2.24 2.24 0 01-4.2 0 2.43 2.43 0 01.49-2.57 2.21 2.21 0 013.21 0zm0 0M55.07 53.81a2.43 2.43 0 01.49 2.57 2.24 2.24 0 01-4.2 0 2.43 2.43 0 01.49-2.57 2.21 2.21 0 013.21 0zm0 0M39.59 53.81a2.43 2.43 0 01.49 2.57 2.24 2.24 0 01-4.2 0 2.43 2.43 0 01.49-2.57 2.21 2.21 0 013.21 0zm0 0"
          fill="#fff"
        />
        <g clipPath="url(#prefix__a)">
          <path
            d="M83.78 57.34V48.8a5.72 5.72 0 00-5.6-5.8h-8.09v-9.39h6.21a.92.92 0 00.81-1.18l-3-10.22a.85.85 0 00-.81-.62h-8.16a.84.84 0 00-.81.62l-3 10.22a1 1 0 00.13.8.86.86 0 00.68.38h6.26V43H29.55A5.72 5.72 0 0024 48.8v8.54a5.69 5.69 0 00-5.22 5.8v13.43a.85.85 0 00.85.87H22v4.4a.9.9 0 00.89.88h9.58a.85.85 0 00.83-.88v-4.4h41.2v4.4a.85.85 0 00.83.88h9.57a.9.9 0 00.89-.88v-4.4h2.4a.85.85 0 00.85-.87V63.14a5.69 5.69 0 00-5.22-5.8zm-18-34h6.95l2.44 8.5H63.33zM29.55 44.75h48.63a4 4 0 013.9 4.05v8.69a5.66 5.66 0 00-4.23 5.65v2.72h-48v-2.72a5.66 5.66 0 00-4.23-5.65V48.8a4 4 0 013.9-4.05zm2 36.21h-7.9v-3.52h7.9zm52.49 0h-7.9v-3.52h7.9zm3.25-5.28H20.43V63.14a4 4 0 013.9-4.05h.37a4 4 0 013.49 4v3.57a.93.93 0 00.89.91h49.58a.93.93 0 00.89-.91v-3.52a4 4 0 013.48-4h.39a4 4 0 013.89 4.05zm0 0"
            fill="#3d3d3f"
          />
        </g>
        <path
          d="M65 59.54a.82.82 0 001.19 0l1.19-1.24a3 3 0 003 0l1.19 1.24a.82.82 0 001.2 0 .9.9 0 000-1.25l-1.19-1.24a3.34 3.34 0 000-3.17l1.19-1.24c.8-.83-.4-2.07-1.19-1.24l-1.19 1.24a3 3 0 00-3 0L66.2 51.4a.82.82 0 00-1.2 0 .9.9 0 000 1.25l1.19 1.24a3.36 3.36 0 000 3.16L65 58.3a.9.9 0 000 1.24zM68.94 54a1.43 1.43 0 011.32.92 1.53 1.53 0 01-.31 1.62 1.39 1.39 0 01-1.29.41 1.44 1.44 0 01-1-.89 1.53 1.53 0 01.13-1.39 1.41 1.41 0 011.15-.67zm0 0M49.55 59.54a.82.82 0 001.19 0l1.19-1.24a3 3 0 003 0l1.19 1.24a.82.82 0 001.2 0 .9.9 0 000-1.25l-1.19-1.24a3.34 3.34 0 000-3.17l1.19-1.24c.8-.83-.4-2.07-1.19-1.24L55 52.66a3 3 0 00-3 0l-1.19-1.24c-.8-.8-2 .41-1.19 1.24l1.19 1.24a3.36 3.36 0 000 3.16l-1.26 1.24a.9.9 0 000 1.24zM53.46 54a1.43 1.43 0 011.32.92 1.53 1.53 0 01-.31 1.62 1.39 1.39 0 01-1.29.41 1.44 1.44 0 01-1-.89 1.53 1.53 0 01.13-1.39 1.41 1.41 0 011.15-.67zm0 0M34.07 59.54a.82.82 0 001.19 0l1.19-1.24a3 3 0 003 0l1.19 1.24a.82.82 0 001.2 0 .9.9 0 000-1.25l-1.14-1.23a3.34 3.34 0 000-3.17l1.19-1.24c.8-.83-.4-2.07-1.19-1.24l-1.19 1.24a3 3 0 00-3 0l-1.19-1.24c-.8-.8-2 .41-1.19 1.24l1.19 1.24a3.36 3.36 0 000 3.16l-1.25 1.25a.9.9 0 000 1.24zM38 54a1.43 1.43 0 011.32.92 1.53 1.53 0 01-.32 1.61 1.39 1.39 0 01-1.29.41 1.44 1.44 0 01-1-.89 1.53 1.53 0 01.13-1.39A1.41 1.41 0 0138 54zm0 0"
          fill="#3d3d3f"
        />
      </g>
    </svg>
  );
};

export default SVG;
