import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './components/Navbar/nav.css';
//import 'rsuite/dist/styles/rsuite-default.css';
import configureStore from './store/store';
import { Provider } from 'react-redux';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('houzspace')
);

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log());
