import React, { Fragment, Suspense } from 'react';
import { useSelector } from 'react-redux';

// import OnlineExperience from '../views/OnlineExperience';
// import ServicesCardGrid from '../views/ServicesGrid';
// import ImageAndIconView from '../views/ImageAndIcon';
// import DesignThemes from '../views/DesignThemes';
// import TwoGridImageAndText from '../views/TwoGridImageAndText';
// import TabSectionView from '../views/TabSection';
// import CustomerReviews from '../views/CustomerReviews';
// import { CustomisedHeadingBlock } from '../views/HeadingBlock';
// import Section from '../components/Sections/Section';
// import MainBanner from './../components/Banners/MainBanner';

import { CustomisedHeadingBlock } from '../views/HeadingBlock';
const Section = React.lazy(() => import('../components/Sections/Section'));
const MainBanner = React.lazy(() =>
  import('./../components/Banners/MainBanner')
);
const OnlineExperience = React.lazy(() => import('../views/OnlineExperience'));
const ServicesCardGrid = React.lazy(() => import('../views/ServicesGrid'));
const ImageAndIconView = React.lazy(() => import('../views/ImageAndIcon'));
const DesignThemes = React.lazy(() => import('../views/DesignThemes'));
const TwoGridImageAndText = React.lazy(() =>
  import('../views/TwoGridImageAndText')
);
const TabSectionView = React.lazy(() => import('../views/TabSection'));
const CustomerReviews = React.lazy(() => import('../views/CustomerReviews'));

/*** Function is used to bifurcation of data ****/

const rollData = (props = []) => {
  if (typeof props === 'undefined') return;
  let data = [];
  if (props.length > 0)
    props.map((e) => {
      return e.display_on.indexOf('Home') > -1 ? data.push(e) : null;
    });
  return data;
};

const Home = () => {
  const BannerData = rollData(useSelector((state) => state.ui.Banner.data));

  const customHeadingData = rollData(
    useSelector((state) => state.ui.CustomHeading.data)
  );
  const ImageIconBlockData = rollData(
    useSelector((state) => state.ui.ImageIcon.data)
  );

  const DesignThemeData = rollData(
    useSelector((state) => state.ui.DesignTheme.data)
  );

  const ServicesGridData = rollData(
    useSelector((state) => state.ui.ServicesGrid.data)
  );

  const TabsData = rollData(useSelector((state) => state.ui.Tabs.data));

  const CustomerFeedbackData = rollData(
    useSelector((state) => state.ui.CustomerFeedback.data)
  );

  const TwoGridData = rollData(useSelector((state) => state.ui.TwoGrid.data));

  return (
    <Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <Section>
          {BannerData.length > 0 ? <MainBanner data={BannerData} /> : null}
        </Section>
        <Section type="FeatureDisplayIcons" wrapper>
          <OnlineExperience {...OnlineExperienceResponse} />
        </Section>
        <Section type="ServiceCardsView">
          {ServicesGridData.length > 0 ? (
            <ServicesCardGrid data={ServicesGridData} />
          ) : null}
        </Section>
        <Section>
          {customHeadingData.length > 0 ? (
            <CustomisedHeadingBlock
              variant={customHeadingData[0].background_color}
              text={customHeadingData[0].heading_title}
            />
          ) : null}
        </Section>
        <Section type="ImageAndIconView">
          {ImageIconBlockData.length > 0 ? (
            <ImageAndIconView {...ImageIconBlockData} />
          ) : null}
        </Section>
        <Section>
          {customHeadingData.length > 0 ? (
            <CustomisedHeadingBlock
              variant={customHeadingData[1].background_color}
              text={customHeadingData[1].heading_title}
            />
          ) : null}
        </Section>
        <Section wrapper={true} type="HomeInteriorTypesView">
          {DesignThemeData.length > 0 ? (
            <DesignThemes data={DesignThemeData} />
          ) : null}
        </Section>
        <Section wrapper={true} type="InfoView">
          {TwoGridData.length > 0 ? (
            <TwoGridImageAndText {...TwoGridData[0]} type="InfoView" />
          ) : null}
        </Section>
        <Section wrapper={true} type="TabView">
          {TabsData.length > 0 ? <TabSectionView data={TabsData} /> : null}
        </Section>
        <Section>
          {/* <SingleImage {...CustomFurnitureImage.image[0]} /> */}
        </Section>
        <Section wrapper={true} type="CustomerReviewView">
          {CustomerFeedbackData.length > 0 ? (
            <CustomerReviews {...CustomerFeedbackData} />
          ) : null}
        </Section>
      </Suspense>
    </Fragment>
  );
};

const OnlineExperienceResponse = {
  items: [
    {
      _id: 'a',
      IconComponent: 'MilestoneIcon',
      title: 'Easy Tracking',
      description: 'Track every milestone from your Infinity dashboard!',
    },
    {
      _id: 'b',
      IconComponent: 'ReportsIcon',
      title: 'Weekly Project Reports',
      description: 'Get weekly reports with fully detailed information',
    },
    {
      _id: 'c',
      IconComponent: 'CompleteControlIcon',
      title: 'Complete Control',
      description:
        'Get the complete control of your project directly from Infinity Dashboard',
    },
    {
      _id: 'd',
      IconComponent: 'PaymentsIcon',
      title: 'Online Payments',
      description: 'Montior your payments to keep the tab of your transactions',
    },
    {
      _id: 'e',
      IconComponent: 'ProductIcon',
      title: 'Wide Range Of Products',
      description:
        'Browse variety range of products from our premium catalouge',
    },
    {
      _id: 'f',
      IconComponent: 'RealTimeIcon',
      title: 'Realtime Monitoring',
      description: 'Montior your project site from anywhere! anytime!',
    },
  ],
};

export default Home;
