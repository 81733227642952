import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

function SentryInit() {
  return Sentry.init({
    dsn:
      'https://84099e8985274780bfb253684bbb7186@o488925.ingest.sentry.io/5573765',
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    release: 'Grafana UI' + '1.0.0',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

function logger(error, scope) {
  return Sentry.withScope(function (error, scope) {
    scope.setLevel('info');
    Sentry.captureException(error);
  });
}

export { SentryInit, logger };
export default SentryInit;
