import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../actions/api';

const slice = createSlice({
  name: 'postData',
  initialState: {
    data: [],
    isSubmitting: false,
    isSubmitSuccess: null,
  },
  reducers: {
    dataSubmitBegan: (data, action) => {
      data.isSubmitting = true;
    },
    dataSubmitCompleted: (data, action) => {
      data.isSubmitting = false;
    },
    dataSubmitFailed: (data, action) => {
      data.isSubmitting = false;
    },
    dataSubmitSuccess: (data, action) => {
      data = action.payload;
      data.isSubmitting = false;
      data.isSubmitSuccess = true;
    },
  },
});

export const {
  dataSubmitBegan,
  dataSubmitCompleted,
  dataSubmitFailed,
  dataSubmitSuccess,
} = slice.actions;

export const postData = (data = {}, form) => async (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `leads/new`,
      method: 'POST',
      data,
      onStart: dataSubmitBegan.type,
      onSuccess: dataSubmitSuccess.type,
      onError: dataSubmitFailed.type,
      onCompleted: dataSubmitCompleted.type,
    })
  );
};
