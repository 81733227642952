import React from 'react';

const SVG = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139 108" {...props}>
      <defs>
        <clipPath id="prefix__a" transform="translate(31)">
          <path fill="none" d="M26.73 20.95h48.89v64.92H26.73z" />
        </clipPath>
      </defs>
      <title>{'trained_labour'}</title>
      <path
        fill="#d6d6d6"
        d="M85 .003l54.002 54.002L85 108.006 30.998 54.005z"
      />
      <path
        fill="#e5e3e4"
        opacity={0.46}
        d="M89.245 4.25l49.752 49.753-49.752 49.752-49.752-49.752z"
      />
      <path
        d="M81.15 49.6a.36.36 0 00-.07-.48 2.18 2.18 0 00-1.79-.46 2.07 2.07 0 00-1.37 1c-.23.38.3.76.53.37a1.44 1.44 0 011-.66 1.59 1.59 0 011.3.34.3.3 0 00.44-.07zm0 0M94.08 49.6a.36.36 0 00-.07-.48 2.18 2.18 0 00-1.79-.46 2.07 2.07 0 00-1.37 1c-.23.38.3.76.53.37a1.44 1.44 0 011-.66 1.59 1.59 0 011.3.34.3.3 0 00.44-.07zm0 0M89.11 58.7h-6.46c-.65 0-1.18.06-1.18-.59a1.18 1.18 0 011.18-1.18h6.47a1.18 1.18 0 011.18 1.18c-.01.65-.54.59-1.19.59z"
        fill="#3d3d3f"
      />
      <rect
        x={102.63}
        y={78.69}
        width={1.18}
        height={5.88}
        rx={0.59}
        ry={0.59}
        fill="#3d3d3f"
      />
      <g>
        <path
          d="M89.47 67.22h6.74a9.34 9.34 0 019.34 9.34v8.25H82V71.88zm0 0"
          fill="#00bcb7"
        />
        <path d="M95.84 43.39H66.68v-4.8H97.4v4.81zm0 0" fill="teal" />
        <path
          d="M96.34 38.59H68a14.2 14.2 0 018.55-12.93l.29 5.08a3 3 0 003 2.84h4.77a3 3 0 003-2.84l.28-5a14.23 14.23 0 018.43 12.88zm0 0"
          fill="#00bcb7"
        />
        <path
          d="M95.84 46v5.77a14.1 14.1 0 01-6.35 10.8 13.27 13.27 0 01-14.62 0 14.1 14.1 0 01-6.3-10.77v-8.41h27.27zm0 0"
          fill="#fff"
        />
        <path
          d="M89.47 67.1v.12L82 71.88l-7.14-4.67V62.5a13.27 13.27 0 0014.62 0zm0 0M87.91 25.71l-.28 5a3 3 0 01-3 2.84h-4.78a3 3 0 01-3-2.84l-.29-5.08a14 14 0 012.15-.73l.48-2.9h6l.49 2.91a14 14 0 012.27.78zm0 0"
          fill="#fff"
        />
        <g clipPath="url(#prefix__a)">
          <path
            d="M76 67.72L83.36 72v11.81H59.52v-7.55c0-4.72 4.3-8.54 9.61-8.54zm0 0"
            fill="teal"
          />
          <path
            d="M58.92 85h46.5c.28 0 1 .2 1.19 0s0-.91 0-1.19v-7.26a10.46 10.46 0 00-10.4-10.45l-6.29.43V63c.72-.54 2.34-2.35 3-3 2.29-2.35 3.16-5.09 3.58-8.35 1.39-.33 2-.42 2.38-1.79s.42-2.58-.6-3.58c-.49-.49-.74-1-1.4-1.19v-.61h.53a1.07 1.07 0 001.07-1.07v-4.82a1.07 1.07 0 00-1.07-1.07 15.31 15.31 0 00-9-12.78 15.11 15.11 0 00-1.76-.65l-.38-2.23a1.07 1.07 0 00-1.12-.86h-6a1.07 1.07 0 00-1.05.89l-.38 2.23a15.08 15.08 0 00-1.63.6A15.26 15.26 0 0067 37.52h-.3a1.07 1.07 0 00-1.07 1.07v4.81a1.07 1.07 0 001.07 1.07h.8v.61a4.21 4.21 0 00-1.79 1 3 3 0 00-.8 2.59c0 1.82 1.21 2.56 3 3a16.69 16.69 0 006.56 10.73v3.58l-6.29.17a10.46 10.46 0 00-10.4 10.45v6.67c0 .28-.2 1 0 1.19s-.28.6 0 .6zm16.69-21.45a15.65 15.65 0 0013.12 0v3.58L82 70.62l-6.39-4.09zm28.86 13v7.18H83.11V72.48l6.67-4.24h6.43a8.32 8.32 0 018.27 8.31zm-7.37-29a1.77 1.77 0 01.51 1.27 1.6 1.6 0 01-.76 1.45v-2.86a.62.62 0 01.24.18zm-30.43-4.27v-4.77l31 .6v4.17zM79 26a1.07 1.07 0 00.79-.86l-.57-3.29h6l-.57 3.29a1.07 1.07 0 00.79.86 13 13 0 011.42.45l-.23 4.3a2 2 0 01-1.94 1.86h-4.84a2 2 0 01-1.94-1.86l-.25-4.33A12.78 12.78 0 0179 26zm-2.19.19l-1 4.68C75.9 33 77.69 34.7 81 33.52h4.77c1 1.18 2.76-.52 2.88-2.69l.18-3.4c3.6 2.16 7.22 5.9 7.6 10.08h-28c.38-4.23 3.45-8 7.12-10.17zM66.58 48.86a1.72 1.72 0 01.58-1.25 1.35 1.35 0 01.32-.24v3a1.79 1.79 0 01-.9-1.49zm3 2.78v-7.18h25.15v7.18a13 13 0 01-5.85 10 12.18 12.18 0 01-13.43 0 13 13 0 01-5.81-10zm-9.71 24.91a8.32 8.32 0 018.27-8.31l6.29-.52 8.94 4.77v11.33l-23.5-.08zm0 0"
            fill="#3c3c3c"
          />
        </g>
        <path
          d="M79.7 49a.37.37 0 00-.07-.49 2.22 2.22 0 00-1.81-.51 2.1 2.1 0 00-1.39 1c-.23.39.3.77.54.38a1.46 1.46 0 011-.66 1.61 1.61 0 011.32.35.31.31 0 00.41-.07zm0 0M92.82 49a.37.37 0 00-.07-.49 2.22 2.22 0 00-1.82-.51 2.1 2.1 0 00-1.39 1c-.23.39.3.77.54.38a1.46 1.46 0 011-.66 1.61 1.61 0 011.32.35.31.31 0 00.45-.07zm0 0M87.78 58.18h-6.56c-.66 0-1.19.06-1.19-.6a1.2 1.2 0 011.19-1.19h6.56a1.2 1.2 0 011.22 1.2c0 .65-.57.59-1.22.59z"
          fill="#3d3d3f"
        />
        <rect
          x={65.72}
          y={78.45}
          width={1.19}
          height={5.96}
          rx={0.36}
          ry={0.36}
          fill="#3c3c3c"
        />
        <rect
          x={101.49}
          y={78.45}
          width={1.19}
          height={5.96}
          rx={0.36}
          ry={0.36}
          fill="#3c3c3c"
        />
        <rect
          x={50.13}
          y={84.86}
          width={71}
          height={1.01}
          rx={0.31}
          ry={0.31}
          fill="#3c3c3c"
        />
        <g>
          <path
            d="M108.9 57.63l8.6 1.82-5.91 22.45a4.82 4.82 0 01-5.49 3.61 4.28 4.28 0 01-3.1-5.42zm0 0"
            fill="#3c3c3c"
          />
          <path
            d="M108.85 61.67l7 1.43-4.96 18.36a4 4 0 01-4.47 3 3.44 3.44 0 01-2.49-4.46zm0 0"
            fill="teal"
          />
          <path d="M107.31 58l8.86-2.37.83 3.05-8.86 2.37zm0 0" fill="#fff" />
          <path
            d="M108.36 49.51a1.72 1.72 0 012.31.76l2.71 5.35a1.722 1.722 0 01-3.07 1.56l-2.71-5.36a1.72 1.72 0 01.76-2.31zm0 0"
            fill="#00bcb7"
          />
          <path
            d="M108.12 61.08L106.5 58a1.64 1.64 0 01-.14-1.16l.64-2.43a1.64 1.64 0 01.9-1.09l2.9-1.32a1.17 1.17 0 011 2.11l-3 1.47a8.14 8.14 0 013 4.54zm0 0"
            fill="#fff"
          />
          <path
            d="M108.65 43.27l-.29 3.53a1.57 1.57 0 001.44 1.7l9.36.78.57-6.88-9.35-.57a1.63 1.63 0 00-1.73 1.44z"
            fill="#00bcb7"
          />
          <g fill="teal">
            <path d="M115.58 51.53l.21-2.53-3.36-.28-.49 5.88h-.24a1.29 1.29 0 00-1 2.07 1.13 1.13 0 00.83.45l4.56.38a1.24 1.24 0 001.31-1.16 1.27 1.27 0 00-.29-.91 1.2 1.2 0 00-.81-.45l-1-.08zM119.69 42.86l-.56 6.7 3.44-.74.05-.51.3-3.64.02-.25-3.25-1.56z" />
          </g>
          <g fill="#00bcb7">
            <path d="M122.93 44.52l-.28 3.36.36.03 3.21.27-.24.36.3-3.56.22-.16-3.21-.27-.36-.03zM127.12 45.05l-.3 3.56 1.76-.8.14-1.65-1.6-1.11z" />
          </g>
          <g fill="#3d3d3f">
            <path d="M111.2 47.41a.47.47 0 00.53-.48l.21-2.48a.47.47 0 00-.44-.56c-.29 0-.54.19-.4.49l-.21 2.48c-.19.27.02.53.31.55zM112.77 47.54a.66.66 0 00.64-.47l.21-2.48a.66.66 0 00-.55-.57c-.29 0-.54.19-.29.5l-.21 2.48c-.31.26-.09.52.2.54zM114.34 47.67a.94.94 0 00.75-.46l.21-2.48c-.16-.3-.38-.56-.67-.58s-.54.19-.17.51l-.21 2.48c-.42.25-.25.51.09.53z" />
            <path d="M108.62 43.64l-.3 3.59v.05a2 2 0 001.91 2.11l1.29.11-.42 5a1.59 1.59 0 00-1.6 1.54 1.69 1.69 0 00.38 1.24 1.71 1.71 0 001.16.6l4.56.38a1.69 1.69 0 10.28-3.36h.26l.21-2.52.75.06c.62-.21 1-1.3 1-1.3l.1-1.15.83.07h.15l3.42-.82a.59.59 0 00.4-.56l2.72.23a.78.78 0 00.28.09l2.18-.89a.7.7 0 00.4-.47v-.11l2.84.24c.27.19.53 0 .55-.31a.47.47 0 00-.48-.53l-2.84-.24v-.53s.36-.39.16-.49l-2.11-1.26-.27.4-2.88-.24c0-.48-.1-.67-.23-.71L120 42.58h-.21l-9-.85a2.1 2.1 0 00-2.17 1.91zm18.43 2.25l.8.51-.08 1-.88.37zm-3.35-.46l2.52.21-.22 2.52-2.51-.16zm-3.24-1.73l2.45 1.09-.3 3.63-2.58.5zm-4 7.9l.14-1.68.84.07-.14 1.68zm-4.5 3.26l.44-5.3 2.52.21-.44 5.3a.54.54 0 00.43.63l1 .08a.7.7 0 01.51.3.8.8 0 01.18.61.83.83 0 01-.83.78l-4.79-.4a.75.75 0 01-.53-.3 1 1 0 01-.15-.61v-.06a.77.77 0 01.82-.72h.25a.59.59 0 00.54-.52zM109.44 44a1 1 0 011.06-1l8.29.48-.48 5.73-8.2-.68a1.05 1.05 0 01-1-1.13z" />
          </g>
          <path
            d="M114.17 53.17l.73-.95a.47.47 0 01.71-.07l2.62 2.56a.62.62 0 010 .8l-.73.95a1 1 0 01-.7.4.93.93 0 01-.74-.27l-1.8-1.79a1.26 1.26 0 01-.09-1.62zM0 6.34"
            fill="#00bcb7"
          />
          <path
            d="M108.13 61.05l8.87-2.37.7 2.61-8.86 2.37zm0 0"
            fill="#7f7778"
          />
        </g>
      </g>
    </svg>
  );
};

export default SVG;
