import React from 'react';

const SVG = (props) => {
  return (
    <svg
      id="prefix__323d3cc3-0c47-406b-abb4-94bc7b409278"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76.53 101.04"
      {...props}
    >
      <defs>
        <style>
          {'.prefix__c26ac1c7-c5a2-44f1-810b-229c0b494611{fill:#00bcb7}'}
        </style>
      </defs>
      <title>{'milestone'}</title>
      <path
        d="M25.56 93.27V13.51c-1.64 0-3 .06-4.34 0-3.28-.16-5.5-2.32-5.48-5.27S18 3.07 21.27 3q9.5-.08 19 0a5.18 5.18 0 015.42 5c.1 3-2 5.2-5.31 5.42-1.34.09-2.7 0-4.21 0v3.59c1 0 1.95.13 2.93.13h49.8c3.78 0 4.13.59 2.34 4-3.25 6.21-6.42 12.47-9.82 18.6-1.21 2.18-1.34 3.81 0 6 3.48 5.58 6.64 11.37 9.9 17.09 1.61 2.82 1.13 3.64-2.12 3.65H36.13v26.99c1.6.15 3.18.32 4.76.43 3.08.22 4.83 1.89 4.91 4.71s-1.77 5.25-4.5 5.31c-6.93.17-13.86.15-20.79 0A4.86 4.86 0 0116 99.19 4.62 4.62 0 0119.82 94c1.72-.38 3.52-.46 5.74-.73zm7.48 0a40.75 40.75 0 00.5-4.74c-.08-7-.29-14-.39-21-.06-3.72 0-3.76 3.65-3.76h51.67c-.71-1.28-1.14-2.1-1.59-2.89-3-5.26-6-10.55-9.11-15.77a3.83 3.83 0 01-.11-4.26c3.26-6 6.39-12.1 9.55-18.16.41-.78.76-1.6 1.3-2.74H35.72c-1.84 0-2.72-.61-2.59-2.53.08-1.24 0-2.5 0-3.73h-4.74v68.2c0 3.24.09 6.49 0 9.72-.06 1.69.88 2.08 2.18 2.09a13.37 13.37 0 002.43-.39zm-2.41-82.63h9c1.75 0 3.19-.48 3.13-2.49-.06-1.86-1.47-2.27-3.1-2.26h-18c-1.68 0-3 .56-3 2.36s1.32 2.41 3 2.4c2.98-.02 5.98-.01 8.97-.01zm.15 90.52h9.23c1.57 0 2.79-.52 2.87-2.22s-1.15-2.53-2.88-2.53H21.84c-1.66 0-3 .58-3 2.39s1.31 2.4 3 2.38c2.96-.04 5.95-.02 8.95-.02z"
        transform="translate(-15.75 -3)"
        fill="#3f3a34"
      />
      <path
        d="M54.45 43.28c2.9-4.11 5.47-7.82 8.11-11.48 1.73-2.4 4.38-2.92 6.46-1.39a4.21 4.21 0 011 5.93q-5.51 8.18-11.32 16.18a4.2 4.2 0 01-6.53.61c-2.7-2.5-5.29-5.12-7.82-7.79a4.38 4.38 0 116.23-6.17c1.31 1.21 2.42 2.59 3.87 4.11zm13.7-9.35c-.43-.47-.88-1.32-1.27-1.29a3.09 3.09 0 00-2.07 1q-4.17 5.69-8.13 11.53c-1.25 1.85-2.37 1.91-3.83.29A29.84 29.84 0 0049 41.71c-.63-.49-1.73-.36-2.62-.51.17.88.05 2 .57 2.61 2 2.23 4.24 4.22 6.27 6.42 1.64 1.78 2.75 1.41 4-.44 3.17-4.66 6.47-9.25 9.7-13.87.39-.57.72-1.16 1.22-1.99z"
        transform="translate(-15.75 -3)"
        fill="teal"
      />
      <path
        className="prefix__c26ac1c7-c5a2-44f1-810b-229c0b494611"
        d="M41.68 23.6c1.26 1.58 2.53 2.48 2.44 3.23-.11.94-1.33 1.76-2.07 2.63-.81-.72-2.18-1.37-2.27-2.17s.94-1.95 1.9-3.69zM78.47 57c-1.63 1-2.6 2.17-3.47 2.13s-2.31-1.55-2.18-2.13a3.56 3.56 0 012.32-2.31c.72-.15 1.71 1.1 3.33 2.31z"
        transform="translate(-15.75 -3)"
      />
    </svg>
  );
};

export default SVG;
