import React from 'react';
import LinkClick from '../../Links/Link';
//import Button from '../Buttons/Buttons';

import ModalSmall from '../ModalSmall';
import ModalLarge from '../ModalLarge';

import TextField from '@material-ui/core/TextField';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailHasError: false,
      passHasError: false,
      apiError: '',
    };
  }

  handleEmailChange = (event) => {
    const isValid = true; //validate('email', event.target.value)
    this.setState({
      email: event.target.value,
      emailHasError: !isValid,
    });
  };

  handlePasswordChange = (event) => {
    const isValid = true; //validate('password', event.target.value, 'required')
    this.setState({
      password: event.target.value,
      passHasError: !isValid,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const validate = true;
    // validate
    if (!validate('email', this.state.email)) {
      this.setState({ emailHasError: true });
      return false;
    }

    if (!validate('password', this.state.password, 'required')) {
      this.setState({ passHasError: true });
      return false;
    }

    this.props
      .signin({
        email: this.state.email,
        password: this.state.password,
      })
      .then((authenticated) => {
        if (authenticated) {
          this.setState({ apiError: '' });
          this.props.onSubmit('/auth/callback');
        } else {
          this.setState({ apiError: 'Invalid Email or Password' });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ apiError: 'Invalid Email or Password' });
      });

    return false;
  };

  handleResetPassword = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      //Router.push('/auth/forgot-password')
    } else {
      // window.openModal(MODAL_NAMES.ForgotPassword);
    }
  };

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-input">
          <TextField
            id="standard-error-helper-tex"
            label="Email"
            variant="outlined"
            error={false}
            value={this.state.email}
            onChange={this.handleEmailChange}
            helperText={
              this.state.emailHasError && 'Email is required or invalid'
            }
            size="small"
            className="w100"
            autoComplete="email"
            color="secondary"
          />
        </div>
        <div className="modal-input">
          <TextField
            id="standard-error-helper-text"
            label="Password"
            variant="outlined"
            error={false}
            value={this.state.email}
            onChange={this.handlePasswordChange}
            helperText={
              this.state.emailHasError && 'Password is required or invalid'
            }
            size="small"
            className="w100"
            autoComplete="email"
            color="secondary"
          />
        </div>

        {this.state.passHasError && (
          <span className="error-small">Password is required.</span>
        )}
        <div className="modal-forgot">
          <label>Forgot your password?</label>
          <LinkClick
            text="Recover here"
            onClick={this.handleResetPassword}
            className="primary"
          />
        </div>
        {this.state.apiError && (
          <span className="error-medium">{this.state.apiError}</span>
        )}
        <div className="modal-footer">
          {/* <Button
            text="Sign In"
            className="modal-loginbtn"
            label={'Log In'}
            type="submit"
          />
          <span className="or">OR</span>
          <Button
            text="Sign In"
            className="modal-otpRequestbtn"
            label={'Log In'}
            type="submit"
          /> */}
        </div>
      </form>
    );
  }

  renderDesktop() {
    return (
      <ModalLarge
        title="Login"
        subtitle="Get Access to your Orders, Transactions and much more..."
        icon=""
      >
        {this.renderForm()}
        <div className="modal-createAccount">
          <label>New to Houzspace?</label>
          <LinkClick
            text="Create an account"
            onClick={this.handleResetPassword}
            className="primary"
          />
        </div>
      </ModalLarge>
    );
  }

  renderMobile() {
    return <ModalSmall>{this.renderForm()}</ModalSmall>;
  }

  render() {
    return <>{this.renderMobile()}</>;
  }
}

export default SignIn;
