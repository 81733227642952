import React from 'react';
import classNames from 'classnames';

const SimpleTextBlock = (props) => {
  let styles = {
    black: 'gray',
    gray: 'gray',
    peal: 'peal',
  }; 

  let cx = classNames.bind(styles);
  let blockClass = cx(props.variant);
  return (
    <div className={`text_block ${blockClass}`}>
      <h1 className="">{props.text}</h1>

      {props.children !== undefined && props.children}
    </div>
  );
};

export default SimpleTextBlock;
